#header {
  z-index: 1000;
  width: 100%;
  padding: 30px 0;
  transition: 0.4s linear;
  margin: 0 auto;

  &.active {
    -webkit-backdrop-filter: blur(10px);
    background: rgba(11, 0, 29, 0.2);
    backdrop-filter: blur(10px);
    padding: 25px 0;
  }
}

#body {
  &.hidden {
    overflow-y: hidden;
  }
}

/* navbar */
.navigation {
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
    width: 120px;
  }
}

.navigation-menu.top {
  margin-left: auto;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
}
.navigation-menu {
  button {
    min-width: 130px;
  }
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 30px;
  position: relative;

  @media (max-width: 1350px) {
    margin: 0 16px;
  }

  &:first-child {
    margin-left: 0px;
  }

  // &:last-child {
  //   margin-right: 0px;
  // }
}

.navigation-menu li.active a {
  color: #9247ff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 3px;
    background: #9247ff;
    border-radius: 30px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
  }
}

.navigation-menu li a {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  // width: 155px;
  justify-content: center;
  transition: all 0.2s linear;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
  }
}

.navigation-menu li a:hover {
  color: #9247ff;
}

.register-btn {
  background: rgba(146, 71, 255, 0.24);
  border: 2px solid #9247ff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  width: 196px;
  font-weight: 500;
  margin-left: auto;
  height: 44px;

  &.mb-15 {
    @media (max-width: 1250px) {
      margin-bottom: 15px;
    }
  }
}

.text-white {
  color: #fff;
}

.social-icons {
  gap: 0px !important;

  li {
    background: rgba(146, 71, 255, 0.24);
    border: 2px solid #9247ff;
    // backdrop-filter: blur(10px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      background: #9247ff;

      a {
        font-weight: 500;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.blog-text {
      border-radius: 100px;
      cursor: pointer;
      margin-left: 30px;

      @media (max-width: 1350px) {
        margin-left: 16px;
      }

      a {
        width: 196px;
        color: #fff;

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          width: 190px;
          height: 40px;
          font-size: 14px;
        }
      }
    }

    &.signIn {
      background: transparent;
      border: transparent;
      cursor: pointer;
      margin: 0 30px;
      backdrop-filter: inherit;
      border-radius: inherit;

      @media (max-width: 1350px) {
        margin: 0 16px;
      }
    }

    a {
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 40px;
      height: 44px;
    }
  }

  .icon {
    &:hover {
      background-color: rgba(146, 71, 255, 0.36);
    }
  }
}

.mobile-navigation {
  display: none;
}

@media (max-width: 1250px) {
  .navigation-menu {
    display: none;
  }

  .mobile-navigation {
    display: block;
  }
}

@media (max-width: 1200px) {
  .navigation-menu {
    .social-icons {
      li {
        margin-left: 0;
        margin-right: 0;
      }
    }

    li {
      margin: 0 15px;
    }
  }
}

.mobile-navigation {
  background: #06001c;
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;

  @media only screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
    // justify-content: start;
    padding-top: 70px;
  }

  .link-navigation {
    @media only screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
      height: 210px;
      overflow-y: auto;
    }

    ul {
      list-style: none;
      text-align: center;
      padding: 0 20px;
      text-transform: uppercase;

      li {
        padding-top: 15px;
        padding-bottom: 15px;

        @media only screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
          padding-bottom: 9px;
          padding-top: 9px;
        }

        a {
          color: #ffffff;
          font-size: 18px;
          display: inline-flex;
          font-weight: 500;
        }
        &.active {
          a {
            color: #9247ff;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 18px;
              height: 3px;
              background: #9247ff;
              border-radius: 30px;
              left: 50%;
              transform: translateX(-50%);
              bottom: -8px;
            }
          }
        }
      }
    }
  }

  .social-navigation {
    @media (max-width: 992px) {
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translate(-50%, -50%);

      @media only screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
        bottom: 95px;
        transform: translate(-50%, -0%);
      }
    }

    .social-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      li {
        a {
          font-size: 16px;
          color: #ffffff;
          display: inline-flex;
        }
      }
    }
  }
}

.hamburger {
  display: none;
}

@media only screen and (max-width: 1250px) {
  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 10;
    padding: 5px;
    border-radius: 8px;

    :is(.bar1, .bar2, .bar3) {
      width: 35px;
      height: 4px;
      background-color: #fff;
      margin: 6px 0;
      border-radius: 15px;
      transition: 0.4s;
    }

    .bar1 {
      width: 26px;
      margin-left: auto;
    }

    .bar3 {
      width: 20px;
      margin-left: auto;
    }

    &.change {
      .bar1 {
        transform: rotate(-45deg) translate(-9px, 6px);
      }

      .bar2 {
        opacity: 0;
      }

      .bar3 {
        transform: rotate(45deg) translate(-8px, -6px);
        width: 26px;
      }
    }
  }
}

// profile dropdown
.user_image_holder {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.user-profile {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  margin-left: 30px;

  @media (max-width: 1350px) {
    margin-left: 16px;
  }

  @media (max-width: 1250px) {
    margin-right: 8px;
  }

  .user-profile-img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 1.5px solid #9247ff;

    img {
      border-radius: inherit;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .user-profile-text {
    display: flex;
    align-items: center;

    .user-profile-text-left {
      display: flex;
      align-items: start;
      flex-direction: column;
      gap: 6px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      img {
        margin-right: 5px;
      }

      .point {
        display: inline;
        font-size: 14px;
        font-weight: 600;
        vertical-align: middle;
        // color: #d4b6ff;
        line-height: 16px;
      }
    }

    .arrow {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: center;
      width: 40px;

      img {
        width: 14px;
      }
    }

    @media (max-width: 1250px) {
      display: none;
    }
  }
}

.user-profile-dropdown {
  background: #06001c;
  border: 1px solid rgba(146, 71, 255, 0.32);
  box-shadow: 0px 0px 20px 0px rgba(146, 71, 255, 0.5);
  backdrop-filter: blu(10px);
  border-radius: 24px;
  min-width: 330px;
  padding: 24px;
  position: absolute;
  right: 0;
  top: 80px;

  .text-holder {
    // display: none;
    margin: 0 0 20px;
    text-align: center;

    .user-img {
      width: 70px;
      height: 70px;
      margin: 0 auto 12px;
      border: 1.5px solid #9247ff;
      border-radius: 100px;

      img {
        border-radius: 100px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h2 {
      margin: 0 0 10px;
      font-weight: 600;
      font-size: 24px;
    }

    p.xp-point {
      // color: #d4b6ff;
      font-weight: 600;
      margin: 0;

      img {
        margin-right: 5px;
      }
    }

    // @media (max-width: 1250px) {
    //   display: block;
    // }
  }

  ul {
    margin-top: 20px;

    li {
      padding-bottom: 20px;
      text-transform: uppercase;

      &:last-child {
        padding-bottom: 0;

        @media (max-width: 1250px) {
          padding-bottom: 20px;
        }
      }

      a {
        color: #fff;

        &:hover {
          color: #9247ff;
        }
      }

      span {
        &:hover {
          color: #9247ff;
        }
      }
    }

    // @media (max-width:1250px){
    //   display: none;
    // }
  }

  .user-logout {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 14px;
    margin-top: 20px;
    transition: 0.3s linear;
    width: max-content;
    text-transform: uppercase;

    @media (max-width: 1250px) {
      margin-top: 0;
    }

    p {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }

    svg {
      path {
        fill: #ffffff;
      }
    }

    &:hover {
      svg {
        path {
          fill: #9247ff;
        }
      }

      p {
        color: #9247ff;
      }
    }
  }

  // a.dash-link{
  //   @media (max-width:1250px){
  //     display: none;
  //   }
  // }
  @media (max-width: 1250px) {
    top: 60px;
  }

  @media (max-width: 420px) {
    right: -55px;
  }
}
