.notification-loader {
  .loader-container {
    height: 400px;
    min-height: 400px;
    @media (max-width: 480px) {
      height: 300px;
      min-height: 300px;
    }
  }
}
.cookies-modal {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #06001c;
  box-shadow: 0px 0px 20px 0px rgba(146, 71, 255, 0.5);
  padding: 20px;
  @media (max-width: 600px) {
    width: 96%;
    left: 2%;
    bottom: 1%;
    text-align: center;
  }
  .cookies-inner {
    display: flex;
    align-items: center;
    max-width: 1400px;
    gap: 20px;
    p {
      font-size: 16px;
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
    @media (max-width: 600px) {
      flex-direction: column;
      gap: 0;
    }
  }
  .button-gap {
    gap: 20px;
    @media (max-width: 600px) {
      gap: 10px;
    }
    button {
      padding: 15px 35px;
      min-width: max-content;
    }
  }
}
.registration {
  .cookies-modal {
    z-index: 3000 !important;
  }
}
.whyModal-error {
  max-width: 600px !important;
  .modalBg {
    border-radius: 40px;
    background: #06001c;
    box-shadow: 0px 0px 20px 0px rgba(146, 71, 255, 0.5);
  }
  .m-auto {
    margin: 0 auto;
    @media (max-width: 600px) {
      width: 200px;
    }
  }
  .crossIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    @media (max-width: 600px) {
      top: 14px;
    }

    img {
      width: 40px !important;
      height: 40px !important;

      @media only screen and (max-width: 600px) {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
  .inner-content-1 {
    padding: 50px;
    @media only screen and (max-width: 600px) {
      padding: 40px 20px;
    }
  }
  .error-modal {
    h3 {
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 267.39%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 30px;
      line-height: 34px;
      text-transform: uppercase;
      margin: 0;
      @media only screen and (max-width: 600px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
    .info-img {
      max-width: 120px;
      margin-bottom: 15px;
      min-height: 120px;
      @media only screen and (max-width: 600px) {
        max-width: 100px;
        min-height: 100px;
      }
    }
  }
  &.w-100 {
    @media (max-width: 600px) {
      width: 96%;
    }
  }
}
.whyModal {
  max-width: 1030px !important;
  &.modal-noti {
    max-width: 600px !important;
  }
  &.modal-noti-1 {
    max-width: 756px !important;
  }

  .modalBg {
    border-radius: 40px;
    background: #06001c;
    box-shadow: 0px 0px 20px 0px rgba(146, 71, 255, 0.5);
  }

  .inner-content {
    position: relative;
    padding: 60px 130px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    @media (max-width: 992px) {
      padding: 60px 30px;
    }

    @media only screen and (max-width: 600px) {
      padding: 60px 20px;
    }

    h2 {
      font-size: 42px;
      line-height: 48px;
      max-width: 730px;
      margin-bottom: 20px;

      @media only screen and (max-width: 992px) {
        font-size: 36px;
      }
      @media only screen and (max-width: 600px) {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 22px;
      color: #fff;
      font-weight: 500;
      margin: 0;
      line-height: 38px;
      letter-spacing: 0.56px;

      @media only screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    button {
      margin-top: 50px;

      @media only screen and (max-width: 600px) {
        margin-top: 25px;
      }
    }
  }

  .inner-content-1 {
    position: relative;
    padding: 50px;
    z-index: 10;
    @media (max-width: 992px) {
      padding: 60px 30px;
    }

    @media only screen and (max-width: 600px) {
      padding: 40px 20px;
    }
    h2 {
      font-size: 32px;
      line-height: 38px;
      max-width: 730px;
      margin-bottom: 20px;
      @media only screen and (max-width: 600px) {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 20px;
      }
      &.text_size {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 6px;
        @media only screen and (max-width: 600px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
    &.notification_modal {
      .noti-text {
        font-size: 20px;
        font-weight: 500;
        margin: 40px 0 0;
        @media (max-width: 600px) {
          font-size: 16px;
          margin: 25px 0 0;
        }
      }
      h5 {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 12px;
      }
      ul {
        &.list-holder {
          height: 400px;
          overflow-y: auto;
          padding-right: 8px;
          @media (max-width: 600px) {
            height: 300px;
          }
        }
        li {
          &.col {
            border-radius: 16px;
            background: #13092e;
            backdrop-filter: blur(5px);
            display: flex;
            align-items: center;
            width: 100%;
            padding: 8px 16px 8px 16px;
            margin-bottom: 16px;
            @media (max-width: 600px) {
              flex-direction: column;
              align-items: self-start;
            }
            &:last-child {
              margin-bottom: 0;
            }
            p {
              margin: 0;
              padding-left: 8px;
              padding-right: 20px;
            }
            button {
              min-width: 148px;
              font-size: 16px;
              margin-left: auto;
              @media (max-width: 600px) {
                margin-left: initial;
                margin-top: 10px;
              }
            }
            .list-msg {
              align-items: start;
              display: flex;
              flex: 1;
            }
            .unread {
              background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.8),
                  rgba(0, 0, 0, 0.8)
                ),
                #32ff5f;
              display: inline-block;
              height: 18px;
              position: relative;
              width: 18px;
              z-index: 0;
              border-radius: 100px;
              margin-top: 3px;
              &::before {
                background: #32ff5f;
                content: "";
                height: 8px;
                left: 5px;
                position: absolute;
                top: 5px;
                width: 8px;
                z-index: -1;
                border-radius: 100px;
              }
            }
          }
        }
      }
      .name {
        font-size: 16px;
        max-width: 600px;
        margin: 0 auto;
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
      .passcode-modal-content {
        margin: 25px 0;
        text-align: left;
        @media (max-width: 600px) {
          margin: 25px 0;
        }
        .list {
          list-style: disc;
          margin-left: 15px;
          padding-bottom: 15px;
          font-size: 16px;
          @media (max-width: 600px) {
            font-size: 15px;
          }
        }
        h6 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 20px;
          @media (max-width: 600px) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .cop-pass {
    margin-bottom: 20px;
    svg.pass-svg {
      margin-right: 8px;
      path {
        fill: #fff;
      }
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .mt-20 {
    margin-top: 20px;
  }
  input {
    &.pass {
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.06);
      background: rgba(255, 255, 255, 0.04);
      backdrop-filter: blur(10px);
      width: 100%;
      height: 54px;
      padding: 0 15px;
      color: #fff;
      margin-bottom: 20px;
      @media (max-width: 600px) {
        height: 44px;
        font-size: 16px;
      }
      &.error {
        border: 1px solid red;
      }
    }
  }
  .m-auto {
    margin: 0 auto;
  }

  .cop-pass {
    margin-bottom: 20px;
    svg.pass-svg {
      margin-right: 8px;
      path {
        fill: #fff;
      }
    }
  }
  input {
    &.pass {
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.06);
      background: rgba(255, 255, 255, 0.04);
      backdrop-filter: blur(10px);
      width: 100%;
      height: 54px;
      padding: 0 15px;
      color: #fff;
      margin-bottom: 20px;
      @media (max-width: 600px) {
        height: 44px;
        font-size: 16px;
      }
    }
  }
  .m-auto {
    margin: 0 auto;
  }

  .cop-pass {
    margin-bottom: 20px;
    svg.pass-svg {
      margin-right: 8px;
      path {
        fill: #fff;
      }
    }
  }
  input {
    &.pass {
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.06);
      background: rgba(255, 255, 255, 0.04);
      backdrop-filter: blur(10px);
      width: 100%;
      height: 54px;
      padding: 0 15px;
      color: #fff;
      margin-bottom: 30px;
      @media (max-width: 600px) {
        height: 42px;
        font-size: 16px;
      }
    }
    &.error {
      border: 1px solid red;
    }
  }
  .m-auto {
    margin: 0 auto;
  }
  .crossIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    @media (max-width: 600px) {
      top: 14px;
      right: 15px;
    }

    img {
      width: 40px !important;
      height: 40px !important;

      @media only screen and (max-width: 600px) {
        width: 30px !important;
        height: 30px !important;
      }
    }
    svg {
      @media (max-width: 480px) {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.webGl-modal {
  padding-top: 0 !important;
  h2 {
    max-width: 868px;
    margin-inline: auto;
  }

  .card-holder {
    margin: 0 -16px;

    @media only screen and (max-width: 1550px) {
      margin: 0 -10px;
    }

    .webGl-card {
      flex: 0 0 auto;
      width: 33%;
      padding: 0 16px;
      margin-bottom: 60px;

      @media only screen and (max-width: 600px) {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 40px;
      }

      .card-img {
        align-items: center;
        display: flex;
        min-height: 70px;
        justify-content: center;
        margin-bottom: 18px;
        width: 100%;

        @media only screen and (max-width: 600px) {
          margin-bottom: 16px;
          min-height: 60px;
        }

        svg {
          height: 70px;

          @media only screen and (max-width: 600px) {
            height: 60px;
          }
        }
      }

      h4 {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin: 0;

        @media only screen and (max-width: 600px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  button {
    margin-top: 0 !important;
    margin-bottom: 35px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 30px !important;

    @media only screen and (max-width: 600px) {
      font-size: 18px !important;
      line-height: 24px !important;
    }
  }
}

.loader_noti {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
