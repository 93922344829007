$accentClr: #9247ff;

.inline {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

.mr-10 {
  margin-right: 10px;
}

.crib-page {
  .top-banner {
    position: relative;
    height: 634px;

    @media only screen and (max-width: 600px) {
      height: 550px;
    }

    &::before {
      position: absolute;
      content: "";
      background: #0C031A;
      opacity: 0.75;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    // .container {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   min-height: 100%;
    //   flex-direction: column;
    //   position: relative;
    //   width: 100%;
    //   height: 100%;
    //   max-width: 1520px;
    //   margin-left: auto;
    //   margin-right: auto;
    //   padding: 0 15px;
    //   text-align: center;
    // }

    h1 {
      font-weight: 700;
      font-size: clamp(34px, 5vw, 56px);
      line-height: clamp(38px, 5vw, 60px);
      text-align: center;
      letter-spacing: -0.03em;
      text-transform: uppercase;
      color: #FFFFFF;
      margin: 0 auto 15px;
      z-index: 5;
    }
    h2 {
      max-width: 870px;
      text-align: left;
      margin: 0 0 20px;
      @media (max-width:600px){
        margin: 0 0 10px;
      }
    }

    p {
      text-align: left;
      max-width: 830px;
      margin: 0 0 20px;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      @media(max-width:600px) {
        gap: 10px;
      }

      .know-more {
        z-index: 2;
      }
      a{ height:48px; display: inline-flex; align-items: center; justify-content: center; padding: 5px 15px;}
    }
  }

  .forehead {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }

    .title-holder {
      order: 1;

      @media only screen and (max-width: 767px) {
        order: 2;
      }

      @media only screen and (max-width: 1180px) {
        order: 1;
      }
    }

    .right-content {
      order: 2;

      @media only screen and (max-width: 767px) {
        order: 1;
      }

      @media only screen and (max-width: 1180px) {
        order: 2;
      }
    }

    .title {
      font-weight: 700;
      font-size: clamp(24px, 5vw, 36px);
      line-height: clamp(38px, 5vw, 54px);
      margin: 0 auto 30px;
    }

    .search-holder {
      width: 345px;
      border: 1.5px solid $accentClr;
      border-radius: 100px;
      padding: 15px;
      margin: 0 auto 30px;
      display: flex;
      align-items: center;

      input {
        &.text {
          width: calc(100% - 35px);
          background-color: transparent;
          box-shadow: none;
          border: 0px;
          color: #fff;
          margin-right: 15px;
        }

        &.submit {
          background: url(https://assets-cdn.virtua.com/images/Virtua_home/search-icon.svg) no-repeat;
          width: 18px;
          height: 18px;
          font-size: 0px;
          background-size: 100%;
          cursor: pointer;
          border: 0px;
        }
      }
    }
  }

  // .cribs-listing {
  //   margin-top: -120px;
  //   z-index: 5;
  //   position: relative;
  //   @media only screen and (max-width: 767px) {
  //     margin-top: -60px!important;
  //   }
  // }

}

.cribs-listing {
  @media only screen and (max-width: 767px) {
    margin-top: 50px;
  }

  .container {
    position: relative;
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
  }

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .custom-col {
    padding: 0 15px;
    flex: 0 0 25%;
    max-width: 25%;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    // @media only screen and (min-width: 768px) and (max-width: 1180px) and (orientation : portrait){
    @media only screen and (min-width: 768px) and (max-width: 1180px) and (orientation : portrait) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1180px) and (orientation : landscape) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }

  .crib {
    margin-bottom: 30px;
    border: 1.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 24px;
    padding: 3px;

    .img-holder {
      position: relative;
      border-radius: 24px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;

      .img {
        width: 100%;
        min-height: 200px;
        object-fit: cover;
        object-position: center;
        border-radius: 20px;

        &.placeholder {
          border-radius: 0px;
          max-height: 100px;
          max-width: 100px;
        }
      }

      .options {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        opacity: 0;
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        transition: all linear 0.25s;
        z-index: -1;
        border-radius: 20px;
      }

      .btn {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 130px !important;
        height: 44px;
        padding: 0px;

        &.link {
          max-width: 34px !important;
          min-width: 34px !important;
          height: 34px;
          left: auto;
          right: 20px;
          top: 10px;
          transform: translate(10px);
          background-image: url(https://assets-cdn.virtua.com/images/Virtua_home/crib-link-icon.svg);
          background-position: center center;
          background-repeat: no-repeat;
        }

        &.flag {
          right: 60px;
          background-image: url(https://assets-cdn.virtua.com/images/Virtua_home/flagicon.svg);
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      &:hover {
        .options {
          opacity: 1;
          z-index: 1;
        }

        .btn {
          opacity: 1;
        }
      }

      .card-actions {
        position: absolute;
        bottom: 10px;
        display: flex;
        // gap: 10px;
        right: 15px;
        font-size: 12px;
        align-items: center;
        z-index: 100;

        div {
          &:first-child {
            margin-right: 10px;
          }
        }

        .card-views,
        .card-likes {
          display: flex;
          align-items: center;

          span {
            padding-left: 5px;
          }

          img {
            width: 14px;
          }
        }

        .card-likes {
          div {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          img {
            cursor: pointer;
          }
        }
      }
    }

    .bottom {
      padding: 0 15px 15px;
      font-size: 12px;

      @media only screen and (max-width: 767px) {
        padding: 0 5px 10px;
      }

      @media only screen and (max-width: 1180px) {
        padding: 0 5px 10px;
      }
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #fff;
      margin: 0 auto 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 30px;
    }

    .meta {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .owner {
        color: $accentClr;
      }

      .pin {
        @media only screen and (max-width: 325px) {
          font-size: 10px;
        }

        img {
          position: relative;
          top: 4px;
          margin-right: 2.5px;
        }
      }

      .tag {
        border: 1.5px solid rgba(255, 255, 255, 0.2);
        border-radius: 100px;
        padding: 2.5px 10px;

        @media only screen and (max-width: 325px) {
          padding: 2.5px 5px;
          font-size: 10px;
        }
      }
    }
  }
}

.loader-container {
  padding: 100px 15px;
  text-align: center;
  width: 100%;
  height: 100vh;

  .img_loader {
    background-color: $accentClr;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin: auto;

    img {
      max-width: 55%;
      max-height: 55%;
    }
  }
}

// copied-text
.copied-text {
  position: absolute;
  right: 0px;
  font-size: 12px;
  background: rgba(0, 0, 0, .75);
  padding: 2.5px 7.5px;
  border-radius: 5px;
  top: -5px;
  z-index: 2;
}
