.game-detail-page {
  position: relative;
  width: 100%;
  height: auto;
  padding: 150px 0 110px;
  min-height: 100vh;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 40px;

    @media (max-width: 990px) {
      padding: 0 40px;
    }

    @media (max-width: 600px) {
      padding: 0 15px;
    }
  }

  .top-heading {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 30px;

    .arrow-icon {
      background: rgba(255, 255, 255, 0.1);
      border: 1.5px solid #9247ff;
      backdrop-filter: blur(15px);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      height: 44px;
      width: 44px;
      cursor: pointer;
      transition: 0.4s ease-in;

      @media (max-width: 600px) {
        height: 34px;
        width: 34px;
      }

      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: 20px;
        transition: 0.4s ease-in;

        @media (max-width: 600px) {
          width: 10px;
        }
      }

      &:hover {
        svg {
          transform: scale(1.5);
        }
      }
    }

    .title {
      font-size: 36px;
      line-height: 32px;
      text-transform: uppercase;
      background: linear-gradient(180deg,
          #fff 0%,
          rgba(255, 255, 255, 0) 267.39%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0;
      flex: 1;

      @media (max-width: 768px) {
        font-size: 26px;
      }
    }
  }

  .flex {
    gap: 50px;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  .left-side {
    // flex: 1 1;
    width: calc(100% - 570px);

    @media (max-width: 1024px) {
      width: 100%;
    }

    .slider-flex {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }

    .swiper-container1 {
      .swiper-wrapper {
        justify-content: center;

        @media (max-width: 600px) {
          justify-content: inherit;
        }
      }

      .swiper-slide {
        @media (min-width: 600px) {
          width: auto !important;
        }

        img {
          border: 1.5px solid rgba(255, 255, 255, 0.1);
          filter: grayscale(100%);
          opacity: 0.4;
          transition: 0.4;
        }

        &.swiper-slide-thumb-active {
          img {
            border: none;
            filter: grayscale(0%);
            opacity: 1;
          }
        }
      }

      .thumb-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 160px;
        max-width: 160px;
        max-height: 140px;

        img {
          object-position: top center;
          width: auto;
          height: auto;
          max-width: 90%;
          max-height: 140px;
        }
      }
    }

    .swiper {
      height: 140px;
    }

    .swiper-container2 {
      height: 500px;

      .swiper-slide {
        padding: 30px;

        @media (max-width: 768px) {
          padding: 0;
        }
      }

      img {
        border-radius: 24px;
        object-fit: contain;
        width: auto;
        margin: 0 auto;

        @media (max-width: 820px) {
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }

  .right-side {
    // flex: 1 1;

    .detail-section {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      @media (max-width: 1025px) {
        gap: 16px;
      }

      .top {
        position: relative;
        padding: 0 0 30px;
        margin: 0 0 20px;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          background: linear-gradient(90deg,
              rgba(224, 203, 255, 0) 0%,
              #fff 50.5%,
              rgba(224, 203, 255, 0) 100%);
          opacity: 0.7;
        }
      }

      h4 {
        font-size: 28px;
        line-height: 32px;
        text-transform: uppercase;
        background: linear-gradient(180deg,
            #fff 0%,
            rgba(255, 255, 255, 0) 267.39%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 0 20px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.16px;
        font-weight: 500;
        margin: 0;
        height: 150px;
        overflow: auto;
      }

      .list {
        display: flex;
        gap: 15px;

        .list-col {
          flex: 1 auto;

          li {
            padding-bottom: 20px;
            display: flex;

            &:last-child {
              padding: 0;
            }

            span {
              margin-left: auto;
              width: 60%;
            }
          }
        }
      }

      .detail {
        position: relative;
        padding: 0 0 30px;
        margin: 0 0 20px;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          background: linear-gradient(90deg,
              rgba(224, 203, 255, 0) 0%,
              #fff 50.5%,
              rgba(224, 203, 255, 0) 100%);
          opacity: 0.7;
        }
      }

      .buttons {
        display: flex;
        gap: 16px;
        // margin-top: auto;
      }

      .btn {
        background: #fff;
        color: #000;
        font-size: 18px;
        line-height: 18px;
        max-width: 100%;
        text-transform: uppercase;

        @media (max-width: 768px) {
          font-size: 14px;
          padding: 10px;
        }

        img {
          max-width: 30px;
          width: 100%;
        }

        &:hover {
          background: #e0e0e0;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: url(https://cdn.virtua.com/Virtua_home/gradient-bg.webp) no-repeat 100% 10%;
    background-size: cover;
  }
}