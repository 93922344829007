.text-overlay{
  position: absolute;
  transition: 2s linear;
  padding: 40px 40px 0;
  z-index: 10;
  bottom: 44px;
  width: 100%;
  @media(max-width: 600px) {
    padding: 40px 20px 0;
    bottom: 20px;
  }
  a{
    margin-left: auto;
    @media(max-width: 600px) {
      margin-right: auto;
    }
  }
  img{
    &.video-icon{
      width: auto;
      height: auto;
      margin-left: auto;
      border-radius: 0 !important;
      @media(max-width: 600px) {
        width: 50px !important;
      }
    }
  }
  .main-title{
    max-width: 560px;
    text-align: left;
    @media(max-width: 600px) {
      margin: 0 0 14px;
    }
    h4{
      text-transform: uppercase;
      @media(max-width: 600px) {
        font-size: 30px;
      }
    }
  }
}