.virtual-wrold-game {
  position: relative;
  width: 100%;
  height: auto;

  .banner {
    position: relative;
    height: 100vh;
    &.min_device{
      @media screen and (max-width: 992px), screen and (max-height: 760px) {
        height: 130vh;
      }
      @media screen and (max-width: 800px), screen and (max-height: 500px) {
        height: auto;
      }
    }
    @media (max-width: 940px) {
      height: auto;
    }

    .slide-bg {
      position: absolute;
      min-height: 100vh;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      &.min_device{
        @media screen and (max-width: 992px), screen and (max-height: 760px) {
          min-height: 130vh;
        }
      }
      &.web {
        @media (max-width: 600px) {
          display: none;
        }
      }

      &.mob {
        display: none;

        @media (max-width: 600px) {
          display: block;
        }
      }
      @media (max-width: 940px) {
        min-height: auto;
        position: relative;
        margin: 0 0 20px;
      }
    }

    .text-holder {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      left: 0;
      padding: 0 40px;
      transform: translateY(-50%);
      text-align: left;
      max-width: 1045px;
      @media (max-width: 940px) {
        //   left: 50%;
        //   top: inherit;
        //   bottom: 0;
        //   transform: translate(-50%, 50%);
        //   align-items: left;
        position: relative;
        top: 0;
        left: 0 !important;
        padding: 0 15px;
        transform: translateY(0%);
      }

      // @media only screen and (min-width: 768px) and (max-width: 1024px) {
      //   top: 70%;
      //   left: 0;
      // }

      // @media only screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
      //   padding-top: 150px;
      //   gap: 10px;

      //   h2 {
      //     font-size: 30px;
      //     line-height: 34px;
      //   }

      //   p {
      //     font-size: 16px;
      //     line-height: 24px;
      //   }
      // }

      .buttons {
        display: flex;
        gap: 16px;
        margin-top: 16px;
        margin-bottom: 44px;
        @media screen and (max-width: 600px), screen and (max-height: 800px) {
          margin-top: 0;
          margin-bottom: 10px;
        }
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
        @media (max-width: 480px) {
          justify-content: left;
        }

        .btn {
          background: #fff;
          backdrop-filter: blur(5px);
          color: #000;
          font-size: 18px;
          max-width: 212px;
          text-transform: uppercase;
          font-weight: 500;

          @media (max-width: 600px) {
            font-size: 13px;
          }
        }
      }
      h2 {
        &.text_title {
          @media (max-width: 480px) {
            font-size: 28px;
          }
        }
      }
    }

    &.dashboard {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          background: linear-gradient(
            180deg,
            rgba(12, 3, 26, 0) 0%,
            rgba(12, 3, 26, 0.3) 80%,
            #0c031a 100%
          );
          width: 100%;
          z-index: 1;
          height: 100%;
        }
      }

      .text-holder {
        p {
          max-width: 650px;
        }

        a {
          margin-top: 8px;
        }

        @media (max-width: 400px) {
          transform: translate(-50%, 10%) !important;
        }
      }
    }
    h2 {
      @media (max-width: 600px) {
        font-size: 26px !important;
      }
    }
  }
  p {
    &.purple {
      font-weight: 500;
      margin: 0 0 8px;
      @media (max-width: 768px) {
        margin: 0;
      }
    }
    &.sdk {
      margin-bottom: 44px;
      text-align: left;
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    padding-left: 20px;
    gap: 10px;
    width: 100%;
    &.gap-60 {
      gap: 60px;
      li {
        width: auto;
      }
      .column-direction {
        gap: 14px;
        @media (max-width: 940px) {
          margin: 0 0 14px;
        }
      }
    }
    @media (max-width: 768px) {
      display: block;
      margin: -10px 0 0;
    }
    li {
      position: relative;
      width: 32%;
      font-size: 18px;
      @media (max-width: 940px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        margin-left: 0;
      }
      &:first-child {
        margin-left: 0;
      }
      &::before {
        background: #9247ff;
        content: "";
        height: 10px;
        left: -22px;
        border-radius: 100px;
        position: absolute;
        top: 7px;
        width: 10px;
      }
    }
    &.beta {
      padding-left: 0;
      li {
        &::before {
          display: none;
        }
      }
    }
  }
  .progress {
    background-color: #d9d9d921;
    border-radius: 8px;
    position: relative;
    margin: 10px 0 0;
    height: 17px;
    width: 215px;
  }

  .progress-done {
    background: #9247ff;
    border-radius: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 10px;
    height: 100%;
    width: 70%;
    font-size: 12px;
    opacity: 1;
    transition: 1s ease 0.3s;
  }
  @media (max-width: 940px) {
    margin: 0 0 40px;
  }
}

.developer {
  font-style: normal;
  color: var(--accentClr);
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  margin-top: -18px;
}
.text-holder {
  &.shelby {
    max-width: 690px !important;
    right: 90px;
    left: inherit !important;
    @media (max-width: 1199px) {
      right: 0;
    }
    @media (max-width: 940px) {
      left: 0 !important;
    }
  }
  &.cardano {
    max-width: 690px !important;
    left: 40px !important;
  }
  .description {
    max-width: 660px;
    font-size: 18px;
    @media (max-width: 940px) {
      text-align: left;
    }
    &.market_place{
      max-width: 620px;
    }
  }
  .item {
    font-size: 18px;
    @media (max-width: 940px) {
      text-align: left;
    }
  }
  &.left {
    left: 40px !important;
  }
  &.top_ {
    top: 53% !important;
    @media (min-width: 1800px) {
      top: 55% !important;
    }
    @media (max-width: 940px) {
      top: 0 !important;
    }
  }
}

.pointer_event_none{
  cursor: not-allowed;
  opacity: 0.75;
}
