// Collection css
.collection {
  position: relative;
  height: 100vh;

  @media only screen and (max-width: 1180px) and (min-width: 320px) {
    height: auto;
  }

  .bg-img {
    min-height: 100vh;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;

    &.web {
      @media(max-width: 1200px) {
        display: none;
      }
    }

    &.mob {
      display: none;

      @media(max-width: 420px) {
        display: block;
      }
    }
  }

  .main-holder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 100px;
    align-items: center;
    z-index: 10;
    justify-content: center;
    padding: 80px 15px 160px;
    max-width: 1672px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 767px) {
      gap: 32px;
      padding: 32px 15px 80px;
      flex-direction: column;
    }

    @media only screen and (max-width: 1180px) and (min-width: 320px) {
      position: relative;
      flex-direction: column;
    }

    @media only screen and (max-width: 1600px) and (min-width: 991px) {
      gap: 20px;
      padding: 80px 15px 160px;
    }

    .content {
      width: 345px;

      @media only screen and (max-width: 1600px) and (min-width: 991px) {
        width: 380px;
      }

      h4 {
        font-size: 64px;
        line-height: 64px;
        font-weight: 700;
        letter-spacing: -2.56px;

        @media only screen and (max-width: 600px) {
          font-size: 32px !important;
        }
      }
    }

    .cardlisting {
      flex: 1;

      .d-grid {
        display: grid;
        grid-row-gap: 48px;
        grid-column-gap: 40px;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        justify-content: center;

        @media only screen and (max-width: 600px) {
          // grid-template-columns: repeat(auto-fill, minmax(188px, 1fr));
          grid-row-gap: 16px;
          grid-column-gap: 8px;
        }

        @media only screen and (min-width: 601px) and (max-width: 990px) {
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }

        @media only screen and (max-width: 1600px) and (min-width: 991px) {
          grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
          grid-column-gap: 28px;
        }

        .custom-col-width {
          width: 100%;
          height: 100%;

          .card {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            min-height: 628px;
            height: 100%;
            padding: 15px;
            border-radius: 16px;
            border: 1.5px solid #23113D;
            background: #1A0633;
            backdrop-filter: blur(10px);


            @media only screen and (max-width: 600px) {
              min-height: 570px;
            }

            // @media only screen and (max-width: 1600px) and (min-width: 991px) {
            //   height: 615px;
            // }

            .card-img {
              display: flex;
              align-items: center;
              justify-content: center;
              // width: 340px;
              // height: 340px;
              border-radius: 12px;

              @media only screen and (max-width: 600px) {
                // width: 145px;
                // height: 145px;
              }

              img {
                max-width: 100%;
                max-height: 100%;
                border-radius: 16px;
              }
            }

            .card-content {
              padding-top: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;

              @media only screen and (max-width: 600px) {
                padding-top: 15px;
              }

              h4 {
                font-size: 24px;
                line-height: normal;
                font-weight: 600;
                margin-bottom: 8px;
                text-align: left;

                @media only screen and (max-width: 600px) {
                  font-size: 20px;
                  margin-bottom: 2px;
                }
              }

              p {
                font-size: 16px;
                font-weight: 400;
                color: #D8D8D8;
                text-align: left;

                @media only screen and (max-width: 600px) {
                  font-size: 14px;
                  line-height: 16px;
                }

                @media only screen and (max-width: 1600px) and (min-width: 991px) {
                  min-height: 105px;
                }
              }

              a {
                width: 100%;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), #9247FF;
                border: transparent;
                color: #F8F8F8;
                margin-top: 66px;

                &:hover {
                  background: #9247FF !important;
                  opacity: 1;
                  transition: 0.8s all;
                }
              }
            }
          }
        }
      }

      @media (max-width: 545px) {
        justify-content: center;
      }

    }
  }
}