.main-slider-wrapper {
  height: 80vh;
  position: relative;
  max-width: 1200px;
  margin: 0 auto 140px;
  padding: 0 20px;
  @media(min-width:1900px) {
    height: 60vh;
  }
  @media(max-width: 600px) {
    height: 50vh;
    margin: 0 auto 80px;
  }
}

.my-custom-pagination-div{
  top: 20% !important;
  right: -4% !important;
  position: absolute;
  transform: none !important;
  @media(max-width: 600px) {
    right: 0 !important;
  }
  .swiper-pagination-bullet-active{
    background: #B476E7 !important;
  }
  .swiper-pagination-bullet{
    margin: 0 !important;
    height: 122px !important;
    border-radius: 0 !important;
    width: 2.5px !important;
    background: #4E4E4E;
    font-size: 20px;
    position: relative;
    span{
      position: absolute;
      bottom: -32px;
      right: -10px;
    }
    &:nth-child(1){
      span{
        top: -32px;
        right: -6px;
        bottom: inherit;
      }
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}
.slider-title{
  margin: 0 0 80px !important;
  @media(max-width: 600px) {
    margin: 0 0 40px !important;
  }
}
.virtuaSlider{
  .swiper-slide{
    img{
      border-radius: 0 !important;
    }
  }
}