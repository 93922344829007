.tvk-landing-page {
  .section-title {
    margin-top: 0;
  }

  .wrapper {
    padding: 80px 0 160px;
  }

  .tvk-mainBanner {
    position: relative;
    background: url(https://assets-cdn.virtua.com/images/Virtua_home/vanry-banner-bg.jpg) no-repeat top center;
    background-size: cover;
    height: auto;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(180deg, rgba(12, 3, 26, 0) 0%, rgba(12, 3, 26, 0.3) 48.54%, rgba(0, 0, 0, 0.9) 100%);
    }

    .wrapper {
      align-items: center;
      grid-template-columns: 55% 45%;
      height: 100%;
      min-height: 70vh;
      padding-bottom: 0;
      position: relative;
      z-index: 4;
    }

    .txt-holder {
      padding-top: 5rem;
      padding-bottom: 5rem;

      .section-title {
        text-transform: uppercase;
      }

      p {
        max-width: 715px;
      }

      .cta {
        display: inline-flex;
        gap: 15px;
        margin-top: 24px;

        .custom-btn {
          width: 100%;
          min-width: 200px;
          text-transform: uppercase;
        }
      }
    }

    .img-holder {
      position: relative;
      right: -100px;
      top: 0;
      margin: 0 auto;
      max-width: 850px;
      height: 100%;
      align-self: flex-end;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }


    .mbl-img {
      object-position: top;
    }
  }

  .tvk-market {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 60px;
    gap: 10px;

    .tvk-market-price {
      .tag {
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 12px;
        color: var(--mutedClr);
      }

      .market-rate {
        font-family: var(--secondaryFont);
        font-size: 26px;
        line-height: 12px;
        display: flex;
        align-items: center;
        color: var(--whiteClr);
        gap: 20px;

        .rates {
          transition: all 0.25s ease-in-out;
          font-size: 14px;
          font-weight: 700;
          // margin-left: 10px;
          display: inline-flex;
          align-items: center;
          gap: 6px;

          &.up {
            color: var(--greenClr);

            svg {
              path {
                fill: var(--greenClr);
              }
            }
          }

          &.down {
            color: var(--redClr);

            svg {
              transform: rotate(180deg);

              path {
                fill: var(--redClr);
              }
            }
          }

          // .txt {
          //   margin-left: 4px;
          // }
        }
      }
    }
  }

  .tvk-token {
    position: relative;
    background: rgba(0, 0, 0, 0.76);
    backdrop-filter: blur(112.5px);

    @media(max-width: 1024px) {
      padding-top: 100px;
    }

    &::before {
      content: '';
      position: absolute;
      background: url(https://assets-cdn.virtua.com/images/Virtua_home/vanry-gradient.png) no-repeat top center;
      background-size: 90% 100%;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
    }

    .wrapper {
      gap: 30px;
      grid-template-columns: repeat(auto-fit, minmax(476px, 1fr)) !important;
      width: 100%;

      @media(max-width: 1600px) {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)) !important;
      }

      @media(max-width: 1250px) {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)) !important;
      }

      @media(max-width: 900px) {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)) !important;
      }
    }

    .tvk-token-cards {
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;

      .wrapper {
        padding-top: 0;
      }

      p {
        font-size: 12px;
        color: var(--mutedClr);
      }

      .tvk-card-cta {
        display: grid;
        justify-items: center;
        gap: 10px;

        .custom-btn {
          font-size: 12px;
          font-weight: 500;
          color: var(--blackClr);
          min-width: 85px;
          width: 100%;

          &.sm {
            height: 34px;
          }

          @media (max-width: 767px) {
            padding: 5px;
          }
        }
      }
    }

    .tvk-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      padding: 25px 15px;
      border: 2px solid transparent;
      border-radius: 24px;
      background-color: rgba(28, 28, 28, 0.3);
      backdrop-filter: blur(22px);
      min-height: 250px;

      @media(max-width: 768px) {
        min-height: 200px;
      }

      &.tvk {
        border-color: var(--tvkClr);
      }

      .tvk-card-cta {
        grid-template-columns: 1fr;

        &.group {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &.binance {
        border-color: var(--binanceClr);

        .custom-btn {
          background-color: var(--binanceClr);
        }
      }

      &.uniswap {
        border-color: var(--uniSwapClr);

        .custom-btn {
          background-color: var(--uniSwapClr);
          color: var(--whiteClr);
        }
      }

      &.coinbase {
        border-color: var(--coinbaseClr);

        .custom-btn {
          background-color: var(--coinbaseClr);
          color: var(--whiteClr);
        }
      }

      &.kraken {
        border-color: var(--krakenClr);

        .custom-btn {
          background-color: var(--krakenClr);
          color: var(--whiteClr);
        }
      }

      &.ascendex {
        border-color: var(--ascendExClr);

        .custom-btn {
          background-color: var(--ascendExClr);
        }
      }

      &.bybit {
        border-color: var(--bybitClr);

        .custom-btn {
          background-color: var(--bybitClr);
        }
      }

      &.gate {
        border-color: var(--gateClr);

        .custom-btn {
          background-color: var(--gateClr);
          color: var(--whiteClr);
        }
      }

      &.mexc {
        border-color: var(--mexcClr);

        .custom-btn {
          background-color: var(--mexcClr);
          color: var(--whiteClr);
        }
      }

      &.paribu {
        border-color: var(--paribuClr);

        .custom-btn {
          background-color: var(--paribuClr);
        }
      }

      &.kucoin {
        border-color: var(--kucoinClr);

        .custom-btn {
          background-color: var(--kucoinClr);
        }
      }

      img {
        margin-bottom: 15px;
        max-width: 184px;
      }

      .tvk-card-address {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background-color: rgba(255, 255, 255, 0.1);
        font-size: 14px;
        padding: 10px 15px;
        color: #fff;
        margin-bottom: 0;

        .address {
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon {
          margin-left: 10px;
          position: relative;

          svg {
            cursor: pointer;
          }

          .tooltip {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            padding: 5px 10px;
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            border-radius: 5px;
            top: -30px;
          }
        }
      }
    }
  }

  .tvk-what-todo {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(https://assets-cdn.virtua.com/images/Virtua_home/tvkBanner.png) no-repeat center #050505;
    background-size: cover;

    .section-title {
      text-align: center;
      margin-bottom: 70px;
    }

    .wrapper {
      position: relative;
      grid-template-columns: 100%;
      height: 100%;
      align-items: center;
      padding-bottom: 160px;
    }

    .tvk-slider-card {
      position: relative;
      overflow: hidden;
      padding: 30px;
      color: var(--whiteClr);
      min-height: 350px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      max-width: 350px;
      margin: 0 auto;
      border-radius: 24px;
      background: rgba(255, 255, 255, .1);
      backdrop-filter: blur(6px);

      @media (max-width: 600px) {
        max-width: max-content;
      }

      .circle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-bottom: 26px;

        img {
          max-width: 100%;
          height: 100%;
          margin: 0 auto;
        }
      }

      .card-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
        margin-top: 0;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: var(--whiteClr);
      }
    }

    .slick-slider {
      position: initial;
    }

    .slick-dots {
      left: 15px;
      bottom: -20px;

      li {
        display: none;
        // width: auto;
        // height: auto;
        font-size: 20px;
        color: var(----mutedClr);
        font-weight: 600;

        &.slick-active {
          display: block;

          button {
            // background: var(--accentClr);
          }
        }

        .customDots {
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--mutedClr);

          .active {
            color: var(--whiteClr);
          }

          .space {
            margin: 0 2px;
          }
        }

        button {

          // width: 100%;
          // height: 100%;
          // background: var(--whiteClr);
          // border-radius: 100%;
          &::before {
            content: none;
          }
        }
      }
    }
  }

  .tvk-available {
    position: relative;
    width: 100%;
    height: 140%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #9247FF;

    .wrapper {
      grid-template-columns: 25% 75%;
      padding: 55px 0;
    }

    .title {
      font-size: 36px;
      line-height: 28px;
      font-weight: 700;
      margin: 0;
    }

    .link {
      display: grid;
      align-items: center;
      justify-items: center;
      gap: 15px;
      grid-template-columns: repeat(4, 1fr);

      img {
        max-width: 90%;
        height: auto;
      }
    }
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  .tvk-landing-page {
    .tvk-mainBanner {
      height: 100%;
      // padding-bottom: 50px;

      .wrapper {
        grid-template-columns: 1fr;
        margin-bottom: 0;
      }

      .txt-holder {
        text-align: center;
        padding-bottom: 0;

        .cta .custom-btn {
          min-width: 150px;
        }
      }

      .img-holder {
        position: relative;
        right: -45px;
      }

      .tvk-market {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 50px;

        .market-rate {
          font-size: 18px;
          line-height: 0;
          gap: 10px;

          .rates {
            font-size: 12px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .tvk-market-price {
          .tag {
            font-size: 12px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .tvk-token {
      // padding-top:400px;
      padding-bottom: 0;
      z-index: 4;

      .wrapper {
        grid-template-columns: 1fr;
        margin: 0;
        gap: 30px;
        grid-auto-rows: minmax(220px, auto);
        padding-bottom: 40px;
        margin-bottom: 40px;

        .tvk-card {
          padding: 15px;

          p {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .tvk-token-cards {
        margin-top: 0;
        top: 0;
      }

      .tvk-token-cards .binance {
        .tvk-card-cta {
          gap: 6px;

          .custom-btn {
            min-width: 100%;
          }
        }
      }
    }

    .tvk-what-todo {
      padding-top: 0;
      height: 100%;

      .wrapper {
        grid-template-columns: 100%;
        height: 100%;
        align-items: center;
        padding-top: 0;
        padding-bottom: 100px;
      }

      .txt-holder {
        text-align: center;
        margin-bottom: 70px;
      }

      .tvk-slider-card {
        margin: 0 auto;
      }

      .slick-dots {
        left: 0;
        bottom: -70px;

        li {
          width: 100%;
        }
      }
    }

    .tvk-available {
      .wrapper {
        grid-template-columns: 100%;

        .title {
          margin-bottom: 25px;
          font-size: 26px;
          text-align: center;
        }
      }

      .link {
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        gap: 40px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .tvk-landing-page {
    .tvk-mainBanner {
      .tvk-market {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        margin-top: 50px;

        .market-rate {
          justify-content: center;
        }

        .tvk-market-price .tag {
          text-align: center;
        }
      }
    }

    .tvk-what-todo {
      .tvk-slider-card {
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tvk-landing-page {
    .tvk-mainBanner {
      height: 100%;

      .wrapper {
        grid-template-columns: 1fr;
        margin-bottom: 0;
        gap: 50px;
      }

      .txt-holder {
        text-align: center;
        padding-bottom: 0;

        .section-detail {
          padding-left: 3rem;
          padding-right: 3rem;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .img-holder {
        max-width: 600px;
        right: -45px;
      }

      .tvk-market {
        gap: 50px;
        margin-top: 50px;

        .market-rate {
          font-size: 18px;

          .rates {
            font-size: 12px;
          }
        }

        .tvk-market-price {
          &:first-child {
            justify-self: flex-end;
          }

          &:last-child {
            justify-self: flex-start;
          }

          .tag {
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }

    .tvk-token {
      // padding-top: 100px;
      // padding-bottom: 100px;

      .wrapper {
        grid-template-columns: 1fr;
        gap: 30px;
        margin: 0;
      }

      .tvk-token-cards {
        position: relative;
        top: 0;
        margin-top: 0;
      }
    }

    .tvk-what-todo {
      // padding-top: 100px;
      height: 100%;

      .wrapper {
        grid-template-columns: 100%;
        height: 100%;
        align-items: center;
      }

      .txt-holder {
        text-align: center;
        margin-bottom: 100px;
      }

      .tvk-slider-card {
        max-width: 350px;
        margin: 0 auto;
      }

      .slick-dots {
        left: 0;
        bottom: -70px;

        li {
          width: 100%;
        }
      }
    }

    .tvk-available {
      .wrapper {
        grid-template-columns: 100%;

        .title {
          margin-bottom: 1rem;
        }
      }

      .link {
        grid-template-columns: repeat(4, 1fr);
        justify-items: self-start;
      }
    }

  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .tvk-landing-page .tvk-token {
    .wrapper {
      gap: 15px;
      grid-template-columns: repeat(2, 1fr);
    }

    .tvk-card-cta .custom-btn {
      margin-bottom: 10px;
    }

    .tvk-token-cards .tvk-card .tvk-card-address .address {
      max-width: 200px;
    }

    .tvk-token-cards .tvk-card p {
      padding: 0;
    }
  }

  .tvk-landing-page .tvk-what-todo .tvk-slider-card {
    max-width: 300px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1190px) {
  .tvk-landing-page .tvk-what-todo .tvk-slider-card {
    max-width: 300px;
  }
}


.virtua-kolect .token .flex span {
  &.up {
    color: var(--greenClr);

    svg path {
      fill: var(--greenClr);
    }
  }

  &.down {
    color: var(--redClr);

    svg {
      transform: rotate(180deg);

      path {
        fill: var(--redClr);
      }
    }
  }
}



// fida
.tvk-mainBanner {
  .wrapper {
    .token-img {
      margin: 0 auto;
      display: flex;

      @media(max-width: 768px) {
        margin-top: 50px;
      }

      @media(max-width: 600px) {
        width: 85%;
      }

      img {
        width: 80%;

        @media(max-width: 1024px) {
          margin: 0 auto;
          align-items: center;
        }

        @media(max-width: 600px) {
          width: 100%;
        }
      }
    }
  }

  .prestige-text {
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #9247FF;
      text-decoration: underline;
      font-size: 14px;

      @media (max-width: 1024px) {
        justify-content: center;
      }
    }
  }
}

@media only screen and (min-width : 280px) and (max-width : 767px) {
  .tvk-landing-page .tvk-token .wrapper {
    display: block;
    overflow: hidden;

    .tvk-card {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}