.news-page {
  padding-top: 108px;
  min-height: calc(100vh - 553px);
  .gap-20 {
    gap: 20px;
  }
  .container {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding: 0 20px;
    position: relative;
    width: 100%;
  }
  ul {
    gap: 14px;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    @media (max-width: 600px) {
      padding-bottom: 8px;
    }
    li {
      transition: 0.3s;
      background: transparent;
      text-transform: uppercase;
      font-weight: 500;
      color: #d7d7d7;
      padding: 15px 25px;
      border: 1px solid #d7d7d7;
      border-radius: 100px;
      cursor: pointer;
      font-size: 16px;
      height: 48px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      &:hover {
        background: #fff;
        color: #000;
      }
      &.active {
        background: #fff;
        color: #000;
      }
    }
  }
  .news-holder {
    margin-top: 60px;
    padding-bottom: 230px;
    @media (max-width: 600px) {
      padding-bottom: 80px;
    }
  }
  .news-list {
    background: #9247ff14;
    border: 1px solid #9247ff4d;
    border-radius: 16px;
    padding: 15px;
    display: flex;
    gap: 24px;
    margin-bottom: 10px;
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
    .news-img-holder {
      width: 480px;
      //   height: 270px;
      overflow: hidden;
      border-radius: 12px;
      @media (max-width: 992px) {
        width: 300px;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
      img {
        // height: 100%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
    .col-right {
      flex: 1;
      padding-top: 25px;
      display: flex;
      flex-direction: column;
      @media (max-width: 600px) {
        padding-top: 0;
      }
      h3 {
        font-size: clamp(20px, 1.55vw, 30px);
        line-height: clamp(24px, 1.75vw, 34px);
        font-weight: 600;
        margin: 0 0 20px;
        @media (max-width: 600px) {
          margin: 0 0 10px;
        }
      }
      p {
        &.title {
          text-align: left !important;
          margin-bottom: 20px;
        }
      }
    }
    .list-footer {
      margin-top: auto;
      p {
        font-weight: 500;
        span {
          color: #9247ff;
        }
      }
    }
  }
  .news_para {
    @media (max-width: 600px) {
      text-align: left !important;
    }
  }
  .no_news {
    background: #9247ff14;
    border: 1px solid #9247ff4d;
    border-radius: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-bottom: 10px;
    min-height: 267px;
    @media (max-width: 600px) {
      min-height: 150px;
    }
  }
}
.space-between {
  justify-content: space-between;
}
address {
  font-style: normal;
}
