.news-detail-page {
  padding-top: 200px;
  @media (max-width: 600px) {
    padding-top: 130px;
  }
  .Banner-img {
    // background: url(https://cdn.bimtvist.com/Virtua_home/news-header.png)
    background:  no-repeat top center;
    background-size: cover;
    background-color: rgba($color: #000000, $alpha: 1);
    min-height: 840px;
    margin: 0 0 60px;

    @media (max-width: 830px) {
      background: url(https://cdn.virtua.com/Virtua_home/game-experience-bg-mb.png)
        no-repeat top center;
      background-size: cover;
    }

    @media (max-width: 600px) {
      margin: 0 0 35px;
      min-height: 500px;
    }

    .container {
      height: 540px;

      @media (max-width: 600px) {
        height: 500px;
      }
    }
  }
  .container {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    padding: 0 20px;
    position: relative;
    width: 100%;
  }
  p {
    span {
      color: #9247ff;
    }
  }
  h1{
    font-size: clamp(22px, 2.3vw, 44px);
    line-height: clamp(26px, 2.5vw, 48px);
    font-weight: 600;
    text-align: center;
    margin: 0 0 30px;
  }
  .news-detail-description{
    padding-bottom: 166px;
    @media (max-width: 600px) {
      padding-bottom: 60px;
    }
    address{
      text-align: center;
      margin: 0 0 30px;
    }
    p{
      margin: 0 0 30px;
      @media (max-width:600px){
        text-align: left !important;
      }
    }
    ul{
      li{
        margin: 0 0 8px;
      }
    }
    a{
      font-weight: 600;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .news-img-holder{
    // height: 540px;
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 58px;
    @media (max-width:768px){
        // height: auto;
        margin-bottom: 30px;
    }
    img{
        width: 100%;
        // height: 100%;
        // object-fit: cover;
    }
  }
}
.fw-500{
    font-weight: 500 !important;
}
.mb-30{
    margin: 0 0 30px;
}
