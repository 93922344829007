@import "node_modules/react-modal-video/scss/modal-video.scss";

.game-landing-page {
  .txt-holder {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mainBanner {
    background: url(https://assets-cdn.virtua.com/images/Virtua_home/web-game-bg.jpg) no-repeat top center;
    background-size: cover;
    background-color: rgba($color: #000000, $alpha: 1);

    .container {
      height: 100vh;
    }

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      background: url(https://assets-cdn.virtua.com/images/Virtua_home/web-game-bg-mob.jpg) no-repeat top center;
      background-size: cover;
    }
  }

  .wrapper {
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    text-align: center;

    .txt-holder {

      // left: 50%;bottom: 0px;
      position: absolute;
      text-align: center;
      // -webkit-transform: translate(-50%);
      // transform: translate(-50%);
      z-index: 4;
      max-width: 870px;
      max-width: 930px;

      .section-title {
        // font-size: 46px;
        // line-height: 46px;
        margin-top: 0;
        text-transform: uppercase;
      }

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        bottom: 50px;
      }
    }

    .scrollDown {
      align-self: flex-end;
      position: absolute;
      bottom: 90px;

      p {
        font-size: 12px;
        margin-top: 1rem;
      }
    }
  }

  .content {
    .container {
      max-width: 930px;
      padding: 150px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .game-landing-page {
    .wrapper .scrollDown {
      bottom: 20px;

      p {
        margin-top: 10px;
      }
    }
  }

  .section-title {
    font-size: 36px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}


.explore-more {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 990px) {
    bottom: 30px;
  }

  .mouse {
    width: 20px;
    height: 32px;
    border: 2px solid #f4f4f4;
    border-radius: 60px;

    &::before {
      content: '';
      width: 2px;
      height: 7px;
      position: absolute;
      background-color: #f4f4f4;
      top: 6px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
      opacity: 1;
      animation: mouse 1.5s infinite;
    }
  }

  img {
    width: 20px;
    height: 32px;
    object-fit: cover;
  }
}

#unlockingMetaverse {
  .section-title {
    font-size: clamp(32px, 5vw, 46px);
    line-height: clamp(32px, 5vw, 46px);
    text-transform: uppercase;
  }
}

@keyframes mouse {
  from {
    opacity: 1;
    top: 4px;
  }

  to {
    opacity: 0;
    top: 22px;
  }
}