.metrics-section {
    padding: 150px 15px 180px;
    position: relative;

    @media (max-width:1600px) {
        padding: 150px 40px 180px;
    }

    .main-content {
        text-align: center;
        max-width: 960px;
        margin: 0 auto;

        // h2 {
        //     font-size: 60px;
        //     line-height: 64px;

        //     @media only screen and (max-width: 600px) {
        //         font-size: 32px;
        //         line-height: 32px;
        //     }
        // }
    }

    .cardlisting {
        padding: 100px 0 0;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;

        @media(max-width: 900px) {
            padding: 32px 0 0;
        }

        @media only screen and (max-width: 1600px) and (min-width: 991px) {
            padding: 80px 0 0;
        }

        .d-grid {
            display: grid;
            grid-row-gap: 60px;
            grid-column-gap: 60px;
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
            justify-content: center;

            @media only screen and (max-width: 600px) {
                grid-row-gap: 16px;
                grid-column-gap: 8px;
            }

            // @media only screen and (max-width: 1400px) and (min-width: 991px) {
            //     grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            // }

            .custom-col-width {
                width: 100%;
                height: 100%;

                .card {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    min-height: 260px;
                    height: 100%;
                    padding: 40px 55px 30px;
                    border-radius: 16px;
                    border: 1px solid rgba(146, 71, 255, 0.30);
                    background: linear-gradient(180deg, rgba(223, 201, 255, 0.08) 0%, rgba(146, 71, 255, 0.08) 100%);
                    backdrop-filter: blur(3px);
                    @media only screen and (max-width: 1500px) {
                        padding: 40px 45px 30px;
                    }

                    @media only screen and (max-width: 600px) {
                        padding-inline: 40px;
                    }

                    .image-holder {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 94px;
                        width: 112px;
                        padding: 25px 30px 17px;
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(146, 71, 255, 0.00) 100%);
                        border-radius: 16px 16px 0 0;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            width: 80%;
                            height: 1px;
                            bottom: -22px;
                            background: linear-gradient(90deg, rgba(224, 203, 255, 0.00) 0%, #FFF 50.5%, rgba(224, 203, 255, 0.00) 100%);
                        }
                    }

                    h4 {
                        color: #FFF;
                        text-align: center;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        margin: 0;
                    }
                }
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 200%;
        background: url(https://cdn.virtua.com/Virtua_home/gradient-bg.webp) no-repeat 100% 100%;
        background-size: cover;
    }

    @media only screen and (max-width: 600px) {
        padding: 75px 15px 60px;
    }
}