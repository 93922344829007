.virtual-wrold {
  position: relative;
  width: 100%;
  height: auto;

  .banner {
    position: relative;
    height: 100vh;
    @media (min-width:1025px){
      height: 105vh;
    }
    @media (max-width:940px){
      height: auto;
    }

    .slide-bg {
      position: absolute;
      min-height: 100vh;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      @media (min-width:1025px){
        min-height: 105vh;
      }

      &.web {
        @media (max-width: 600px) {
          display: none;
        }
      }

      &.mob {
        display: none;

        @media (max-width: 600px) {
          display: block;
        }
      }
      @media (max-width:940px){
        min-height: auto;
        position: relative;
        margin: 0 0 20px;
      }
    }

    .text-holder {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      left: 0;
      padding: 0 40px;
      transform: translateY(-50%);
      text-align: left;
      max-width: 1045px;
      @media (max-width:940px){
        position: relative;
        top: 0;
        left: 0;
        padding: 0 15px 20px;
        transform: translateY(0%);
      }
      &.one {
        max-width: 855px;
      }

      // @media (max-width: 400px) {
      //   transform: translate(-50%, 20%) !important;
      // }

      // @media (max-width: 600px) {
      //   left: 50%;
      //   top: inherit;
      //   bottom: 20%;
      //   text-align: center;
      //   transform: translate(-50%, 50%);
      //   align-items: left;
      //   padding: 0 15px;
      // }

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        top: 70%;
        left: 0;
      }

      @media only screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
        // padding-top: 150px;
        gap: 10px;

        h2 {
          font-size: 30px;
          line-height: 34px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .buttons {
        display: flex;
        gap: 16px;
        margin-top: 16px;
        margin-bottom: 44px;
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
        @media (max-width: 480px) {
          justify-content: left;
        }

        .btn {
          background: #fff;
          backdrop-filter: blur(5px);
          color: #000;
          font-size: 18px;
          max-width: 212px;
          text-transform: uppercase;
          font-weight: 500;

          @media (max-width: 600px) {
            font-size: 13px;
          }
        }
      }
      h2 {
        &.text_title {
          @media (max-width: 480px) {
            font-size: 28px;
          }
        }
      }
    }

    &.dashboard {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          background: linear-gradient(
            180deg,
            rgba(12, 3, 26, 0) 0%,
            rgba(12, 3, 26, 0.3) 80%,
            #0c031a 100%
          );
          width: 100%;
          z-index: 1;
          height: 100%;
        }
      }

      .text-holder {
        p {
          max-width: 650px;
        }

        a {
          margin-top: 8px;
        }

        @media (max-width: 400px) {
          transform: translate(-50%, 10%) !important;
        }
      }
    }
  }
  p{
    &.purple{
      font-weight: 500;
      margin: 0 0 8px;
      @media (max-width: 768px) {
        margin: 0;
      }
    }
    &.sdk{
      margin-bottom: 44px;
      text-align: left;
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
  ul {
    margin: 0;
    list-style-type: none;
    padding-left: 20px;
    @media(max-width:768px){
      display: block;
    }
    li {
      position: relative;
      margin-left: 40px;
      @media(max-width:768px){
        margin-left: 0;
      }
      &:first-child{
        margin-left: 0;
      }
      &::before {
        background: #9247FF;
        content: "";
        height: 10px;
        left: -22px;
        border-radius: 100px;
        position: absolute;
        top: 7px;
        width: 10px;
      }
    }
  }
}
