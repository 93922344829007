.textHolder {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  min-height: 100vh;
  max-width: 800px;

  h4 {
    line-height: 56px;
    margin: 0 0 10px;

    .arrowicon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      @media(max-width: 600px) {
        img {
          width: 34px;
          height: 30px;
        }
      }
    }
  }

  p {
    margin: 0 0 30px;

    @media (max-width: 990px) {
      text-align: center;
    }
  }

  a {
    margin: 0 0 80px;

    @media(max-width: 1600px) {
      margin: 0 0 60px;
    }
  }

  @media (max-width:600px) {
    align-items: center;
    padding-bottom: 0;
    justify-content: flex-end !important;
    min-height: 820px;
  }
}

.mbl-img {
  display: none;
}

@media (max-width: 992px) {
  .web-img {
    display: none;
  }

  .mbl-img {
    display: block;

    @media (min-width: 768px) and (max-width: 1180px) {
      object-position: top;
    }
  }

  .metaverseGames::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    top: 0;
    left: 0;
  }
}

@media (max-width: 600px) {
  .mbl-img {
    min-height: 100vh;
    height: 100%;
  }

  .metaverseGames::after {
    display: none;
  }
}

@media (max-width: 600px) {
  .metaverseGames {
    min-height: 890px;
    height: 100%;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
  .metaverseGames {
    min-height: 600px;
  }

  .textHolder {
    min-height: 600px;
    max-width: 425px;
  }

  .web-img {
    display: block;
    min-height: 600px;
    height: 100%;
  }

  .mbl-img {
    display: none;
  }
}