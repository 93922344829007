.virtua-feature {

  .main-content {
    text-align: center;

    h4 {
      margin: 100px 0 0;

      @media(max-width: 600px) {
        margin: 60px 0 0;
        font-size: 32px;
      }
    }

  }

  .cardlisting {
    padding: 80px 15px 160px;
    width: 100%;
    max-width: 1672px;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: 900px) {
      padding: 32px 15px 80px;
    }

    @media only screen and (max-width: 1600px) and (min-width: 991px) {
      padding: 80px 15px 160px;
    }

    .d-grid {
      display: grid;
      grid-row-gap: 48px;
      grid-column-gap: 24px;
      grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
      justify-content: center;

      @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(auto-fill, minmax(187px, 1fr));
        grid-row-gap: 16px;
        grid-column-gap: 8px;
      }

      @media only screen and (min-width: 601px) and (max-width: 990px) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }

      @media only screen and (max-width: 1600px) and (min-width: 991px) {
        grid-template-columns: repeat(auto-fill, minmax(339px, 1fr));
      }

      .custom-col-width {
        width: 100%;
        height: 100%;

        .card {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          min-height: 528px;
          height: 100%;
          padding: 26px;
          border-radius: 24px;
          background: rgba(26, 6, 51, 0.20);
          backdrop-filter: blur(10px);

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(https://assets-cdn.virtua.com/images/Virtua_home/feature-card-border.png) no-repeat;
            background-size: 100% 100%;
            z-index: -1;
          }

          @media only screen and (max-width: 600px) {
            min-height: 256px;
            padding: 20px 4px;
          }

          .card-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 344px;
            height: 344px;

            @media only screen and (max-width: 600px) {
              width: 145px;
              height: 145px;
            }

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 20px;
            text-align: center;

            @media only screen and (max-width: 600px) {
              padding-top: 0;
            }

            h4 {
              font-size: 36px;
              font-weight: 700;
              margin-bottom: 16px;

              @media only screen and (max-width: 600px) {
                font-size: 18px;
                margin-bottom: 8px;
                line-height: 20px;
              }

              @media only screen and (max-width: 1600px) and (min-width: 991px) {
                font-size: 30px;
              }
            }

            p {
              font-size: 20px;
              font-weight: 400;
              color: #D8D8D8;

              @media only screen and (max-width: 600px) {
                font-size: 10px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 545px) {
      justify-content: center;
    }

  }
}