.brand-section {
  .brand-slider {
    .section-holder {
      mask-image: linear-gradient(90deg, #0000, #000 10%, #000 90%, #0000);
      @media only screen and (min-width: 320px) and (max-width: 767px) {
        top: 50px;
      }
      .swiper-wrapper {
        transition-timing-function: linear;
      }
      .grid {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 150px;
        height: 100%;
        padding: 49px 37px;
        border-radius: 16px;
        border: 1px solid rgba(146, 71, 255, 0.3);
        background: linear-gradient(
          180deg,
          rgba(223, 201, 255, 0.08) 0%,
          rgba(146, 71, 255, 0.08) 100%
        );
        // display: inline-flex;
        // min-height: 130px;
        // background: rgba(255, 255, 255, 0.1);
        // border-radius: 24px;
        // position: relative;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // transition: .25s;
        // backdrop-filter: blur(18px);
        @media (max-width: 1600px) {
          min-height: 120px;
        }
        @media (max-width: 768px) {
          min-height: 105px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          background-color: transparent;
          border-top-left-radius: 24px;
          border-top: 2px solid transparent;
          border-left: 2px solid transparent;
          border-color: #9247ff;
          display: none;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 20px;
          height: 20px;
          background-color: transparent;
          border-bottom-right-radius: 24px;
          border-bottom: 2px solid transparent;
          border-right: 2px solid transparent;
          border-color: #9247ff;
          display: none;
        }
        &:hover {
          :is(span.sp:first-child, span.sp:nth-child(3)) {
            width: 80px;
            @media (max-width: 768px) {
              width: 40px;
            }
          }
          :is(span.sp:nth-child(2), span.sp:nth-child(4)) {
            height: 80px;
            @media (max-width: 768px) {
              height: 40px;
            }
          }
        }
        span.sp {
          position: absolute;
          transition: 0.52s;
          background-color: transparent;
          display: none;
          &:first-child {
            background: linear-gradient(
              to left,
              rgba(255, 255, 255, 0.1),
              #9247ff 30%
            );
            width: 40px;
            height: 2px;
            top: 0px;
            left: 20px;
            @media (max-width: 768px) {
              width: 20px;
            }
          }
          &:nth-child(2) {
            background: linear-gradient(
              to top,
              rgba(255, 255, 255, 0.1),
              #9247ff 30%
            );
            width: 2px;
            height: 40px;
            top: 20px;
            left: 0px;
            @media (max-width: 768px) {
              height: 20px;
            }
          }
          &:nth-child(3) {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0.1),
              #9247ff 30%
            );
            width: 40px;
            height: 2px;
            bottom: 0px;
            right: 20px;
            @media (max-width: 768px) {
              width: 20px;
            }
          }
          &:nth-child(4) {
            background: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0.1),
              #9247ff 30%
            );
            width: 2px;
            height: 40px;
            bottom: 20px;
            right: 0px;
            @media (max-width: 768px) {
              height: 20px !important;
            }
          }
        }
        img {
          width: 80%;
        }
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
      min-height: inherit;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  @media only screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
    min-height: 750px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 150px;
  height: 100%;
  padding: 49px 37px;
  border-radius: 16px;
  border: 1px solid rgba(146, 71, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(223, 201, 255, 0.08) 0%,
    rgba(146, 71, 255, 0.08) 100%
  );

  @media only screen and (max-width: 600px) {
    padding: 25px 18px;
    min-height: 110px;
  }

  @media only screen and (max-width: 1024px) and (min-width: 991px) {
    padding: 35px;
    min-height: 125px;
  }

  .card-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;

    @media only screen and (max-width: 600px) {
      height: 34px;
    }

    @media only screen and (max-width: 1024px) and (min-width: 991px) {
      height: 40px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &.cactus {
    padding: 0;
    .card-img {
      height: 88px;
      @media (max-width: 600px) {
        height: 60px;
      }
    }
  }
  &.cardano {
    padding: 0;
    .card-img {
      height: 88px;
      @media (max-width: 600px) {
        height: 34px;
      }
    }
  }
  &.roblox {
    padding: 0;
    .card-img {
      height: 38px;
      @media (max-width: 600px) {
        height: 24px;
      }
    }
  }
  &.new_ {
    padding: 0;
    .card-img {
      height: 68px;
      @media (max-width: 600px) {
        height: 48px;
      }
    }
  }
}
.pointer-events-none {
  pointer-events: none;
}
