.partner-section {
  position: relative;
  padding: 0 15px 180px;

  @media (max-width: 1600px) {
    padding: 0 40px 120px;
  }
  @media (max-width: 600px) {
    padding: 0 15px 60px;
  }

  .main-content {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 600px) {
      margin-bottom: 50px;
    }
  }

  .cardlisting {
    padding: 80px 0 0;
    width: 100%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 990px) {
      padding: 32px 0 80px;
    }

    @media only screen and (max-width: 1600px) and (min-width: 991px) {
      padding: 80px 0 160px;
    }

    .d-grid,
    .d-grid-1 {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
      justify-content: center;

      @media only screen and (max-width: 600px) {
        gap: 16px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }

      @media only screen and (max-width: 1024px) and (min-width: 991px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }

      .custom-col-width {
        width: 100%;
        height: 100%;

        .card {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          min-height: 150px;
          height: 100%;
          padding: 49px 37px;
          border-radius: 16px;
          border: 1px solid rgba(146, 71, 255, 0.3);
          background: linear-gradient(
            180deg,
            rgba(223, 201, 255, 0.08) 0%,
            rgba(146, 71, 255, 0.08) 100%
          );

          @media only screen and (max-width: 600px) {
            padding: 25px 18px;
            min-height: 110px;
          }

          @media only screen and (max-width: 1024px) and (min-width: 991px) {
            padding: 35px;
            min-height: 125px;
          }

          .card-img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;

            @media only screen and (max-width: 600px) {
              height: 34px;
            }

            @media only screen and (max-width: 1024px) and (min-width: 991px) {
              height: 40px;
            }

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          &.cactus{
            padding: 0;
            .card-img{
                height: 88px;
                @media(max-width:600px){
                  height: 60px;
                }
            }
          }
          &.cardano{
            padding: 0;
            .card-img{
                height: 88px;
                @media(max-width:600px){
                  height: 34px;
                }
            }
          }
        }
      }
    }
    &.partner_list {
      .d-grid-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .custom-col-width {
            width: calc(25% - 18px);
            height: 100%;
            @media (max-width:1320px){
                width: calc(33.33% - 18px);
            }
            @media (max-width:992px){
                width: calc(50% - 16px);
            }
        }
      }
    }
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   z-index: -1;
  //   width: 100%;
  //   height: 100%;
  //   background: url(https://cdn.virtua.com/Virtua_home/gradient-bg.webp)
  //     no-repeat 100% 100%;
  //   background-size: cover;
  // }
}
