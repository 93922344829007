.text-uppercase {
  text-transform: uppercase;
}
.contentPages {
  background: #0c031a;
}
.contentPages .content h5 {
  font-size: 18px;
  font-weight: 600;
}
.contentPages .content {
  padding: 150px 0;
}
.contentPages .content .main-title {
  font-size: 56px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0.02em;
  color: #f8f8f8;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}
.contentPages .content .top_notice {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #f8f8f8;
  margin-bottom: 50px;
  text-transform: uppercase;
}
.contentPages .content ul {
  /* max-width: 1170px; */
  margin-top: 0px;
  margin-bottom: 34px;
  padding-inline-start: 20px;
}
.contentPages .content ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 1px;
  color: #f8f8f8;
  margin-bottom: 16px;
}
.contentPages .content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1;
  color: #f8f8f8;
}
.contentPages .content .link {
  color: #a364ff;
}
.contentPages .content .link:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .contentPages .content {
    padding: 100px 0 50px;
  }
  .contentPages .content .main-title {
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .contentPages .content .top_notice {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 25px;
  }
  .contentPages .content ul {
    margin-bottom: 0px;
  }
  .contentPages .content ul li {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .contentPages .content p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .contentPages .content {
    padding: 120px 0 50px;
  }
  .contentPages .content .main-title {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 20px;
  }
  .contentPages .content .top_notice {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .contentPages .content ul li {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .contentPages .content p {
    font-size: 14px;
    line-height: 22px;
  }
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #dee2e6;
}
.table {
  border-collapse: collapse;
}
.table td,
.table th {
  padding: 1.25rem 1rem;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}
.table tbody th,
.table td {
  padding: 0.5rem 1rem;
  padding: 21px 1rem;
}
@media screen and (max-width: 600px) {
  .table-responsive {
    overflow-x: auto;
  }
  .table td {
    white-space: nowrap;
  }
}
