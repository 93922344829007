.virtua-prime{
  padding: 50px 0 100px;
  @media(max-width: 992px) {
    padding: 10px 0 80px;
  }
  .main-title{
    max-width: 760px;
    margin: 0 auto 80px;
    text-align: center;
    @media(max-width: 992px) {
      max-width: 600px;
    }
    @media(max-width: 600px) {
      margin: 0 auto 40px;
    }
    h4{
      margin: 0 0 16px;
    }
  }
  .main-plot{
    gap: 60px;
    margin: 0 0 80px;
    flex-wrap: wrap;
    @media(max-width: 1000px) {
      gap: 30px;
    }
    .card{
      flex: 1;
      background: #090211;
      padding: 30px;
      width: 100%;
      border: 2px solid rgba(185, 142, 255, 0.7);
      box-shadow: 0 0 15px rgba(146, 71, 255, 0.79);
      border-radius: 36px;
      @media(max-width: 1000px) {
        padding: 20px;
      }
      @media(max-width: 600px) {
        flex: inherit;
      }
      .image-holder{
        img{
          width: 100%;
        }
      }
      .title-holder{
        background: rgba(74, 74, 74, 0.2);
        backdrop-filter: blur(10px);
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        margin-bottom: 24px;
        @media(max-width: 1000px) {
          height: 60px;
        }
        h5{
          margin: 0;
          font-size: 26px;
          font-weight: 600;
          color: #fff;
          span{
            color: #9247FF;
          }
        }
      }
      .meta-info{
        color: #fff;
        justify-content: space-around;
        text-align: center;
        @media(max-width: 600px) {
          gap: 10px;
          font-size: 14px;
        }
        span{
          display: block;
          color: #9247FF;
          font-weight: 600;
          margin: 8px 0 0;
        }
      }
      &:hover{
        box-shadow: 0 0 30px rgba(146, 71, 255, 0.79);
      }
    }
  }
  .community{
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    max-width: 760px;
    margin: 0 auto;
    padding: 30px;
    @media(max-width: 600px) {
      padding: 25px;
      flex-wrap: wrap;
    }
    h4{
      color: #C76CFF;
      @media(max-width: 600px) {
        margin: 0 0 30px;
      }
    }
    .main-plot{
      margin: 0;
      gap: 20px;
      width: 60%;
      @media(max-width: 600px) {
        width: 100%;
      }
      .card{
        border-radius: 16px;
        padding: 20px;
        text-align: center;
        span{
          display: block;
          color: #9247FF;
          font-weight: 600;
          margin: 8px 0 0;
        }
      }
    }
  }
}