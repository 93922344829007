.mb-30 {
    margin-bottom: 30px !important;
}
.mb-18 {
    margin-bottom: 18px !important;
}

.mb-44 {
    margin-bottom: 44px !important;
}

.sdk-section {
    padding: 0 15px 180px;
    position: relative;

    @media (max-width:1600px) {
        padding: 0 40px 180px;
    }

    .main-content {
        text-align: center;
        max-width: 860px;
        margin: 0 auto;

        h2 {
            margin-bottom: 16px;
        }
    }

    .cardlisting {
        padding: 80px 0 0;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;

        @media(max-width: 900px) {
            padding: 32px 0 0;
        }

        @media only screen and (max-width: 1600px) and (min-width: 991px) {
            padding: 80px 0 160px;
        }

        .d-grid {
            display: flex;
            grid-row-gap: 50px;
            grid-column-gap: 50px;
            // grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 992px) {
                display: grid;
            }
            @media only screen and (max-width: 600px) {
                display: grid;
                grid-row-gap: 16px;
                grid-column-gap: 8px;
                grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
            }

            @media only screen and (max-width: 1400px) and (min-width: 991px) {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
                grid-column-gap: 20px;
            }

            @media only screen and (min-width: 901px) and (max-width: 1200px) and (orientation: landscape) {
                grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
            }

            .custom-col-width {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;

                @media only screen and (min-width: 320px) and (max-width: 1024px) {
                    display: inherit;
                }

                @media only screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
                    display: inherit;
                }

                @media only screen and (min-width: 901px) and (max-width: 1200px) and (orientation: landscape) {
                    display: flex;
                }

                .card {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    border-radius: 16px;
                    border: 1px solid rgba(146, 71, 255, 0.30);
                    background: linear-gradient(180deg, rgba(223, 201, 255, 0.08) 0%, rgba(146, 71, 255, 0.08) 100%);
                    backdrop-filter: blur(3px);
                    padding: 40px 50px 52px;

                    @media only screen and (max-width: 1024px) {
                        padding-inline: 25px;
                        height: 100%;
                    }

                    @media only screen and (min-width: 901px) and (max-width: 1024px) and (orientation: landscape) {
                        padding-inline: 20px;
                    }

                    &.dark {
                        border: 1px solid #E3D0FF;
                        background: linear-gradient(180deg, #9247FF 0%, #32155D 100%);
                    }

                    h3 {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            bottom: -20px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 50%;
                            height: 1px;
                            background: linear-gradient(90deg, rgba(224, 203, 255, 0.00) 0%, #FFF 50.5%, rgba(224, 203, 255, 0.00) 100%);
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        padding: 0 15px 60px;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 70%;
        height: 200%;
        background: url(https://cdn.virtua.com/Virtua_home/gradient-bg.webp) no-repeat 100% 100%;
        background-size: cover;
    }
}