.heroBnner {
  height: 100vh;
  width: 100%;
  position: relative;

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    min-height: 550px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    min-height: 430px;
  }

  @media only screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
    min-height: 100vh;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;

    &.web {
      @media (max-width: 600px) {
        display: none;
      }
    }

    &.mob {
      display: none;

      @media (max-width: 600px) {
        display: block;
      }
    }
    @media (max-width: 600px) {
      max-width: 150px;
      min-width: 150px;
    }
  }
  .heroTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 980px;
    width: 100%;
    text-align: center;
    z-index: 2;
  }
  .title-holder {
    p {
      margin-bottom: 6px;
      font-weight: 500 !important;
      &:last-child {
        margin-bottom: 33px;
        @media (max-width: 600px) {
          margin-bottom: 20px;
        }
      }
      &.title {
        font-size: 28px;
        font-size: clamp(20px, 1.45vw, 28px);
        line-height: 32px;
        margin-bottom: 15px;
        @media (max-width: 600px) {
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
    }
    .sub-title {
      color: #bf93ff;
      span {
        color: #fff;
      }
    }
    @media (max-width: 600px) {
      padding: 0 15px;
    }
  }
  button {
    margin: 0 auto;
    min-width: 240px;
    height: 54px;
    border: 2px solid #ba8bff;
    backdrop-filter: blur(20px);
    box-shadow: 4px 4px 14.4px 0px #a96ffe66;
    @media (max-width: 600px) {
      min-width: 180px;
    }
  }
  &::before {
    position: absolute;
    bottom: 0px;
    left: 0px;
    content: "";
    background: linear-gradient(
      rgba(12, 3, 26, 0) 0%,
      rgba(12, 3, 26, 0.3) 48.54%,
      rgb(12, 3, 26) 100%
    );
    width: 100%;
    z-index: 1;
    height: 100%;
  }
}

.arrow_holder {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 5%;
  margin: 0 auto;
  left: 50%;
  z-index: 10;
}
.chevron {
  position: absolute;
  width: 28px;
  height: 2px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #9247FF;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}
@keyframes pulse {
  to {
    opacity: 1;
  }
}
