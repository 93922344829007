$accentClr: #9247ff;

.inline {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

.mr-10 {
  margin-right: 10px;
}

.metaverse-page {
  .top-banner {
    position: relative;
    height: 540px;

    @media only screen and (max-width: 600px) {
      height: 550px;
    }

    &::before {
      position: absolute;
      content: "";
      background: #0C031A;
      opacity: 0.75;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .container {
      display: flex;
      justify-content: end;
      min-height: 100%;
      flex-direction: column;
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 40px 15px;

      @media only screen and (max-width: 600px) {
        padding: 0 15px 15px;
      }
    }

    h1 {
      font-weight: 700;
      font-size: clamp(34px, 5vw, 64px);
      line-height: clamp(38px, 5vw, 64px);
      text-align: center;
      letter-spacing: -2.56px;
      text-transform: capitalize;
      color: #FFFFFF;
      margin: 0 auto 15px;
      z-index: 5;
    }

    p {
      z-index: 5;
      max-width: 700px;

      @media only screen and (max-width: 600px) {
        text-align: left;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      @media only screen and (max-width: 420px) {
        width: 100%;
      }

      .know-more {
        z-index: 2;
      }

      a {
        height: 56px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 16px 32px;

        @media only screen and (max-width: 420px) {
          height: 48px;
          padding: 0px 20px;
          width: 50%;
          font-size: 12px;
        }
      }
    }
  }

  .forehead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    // @media only screen and (max-width: 767px) {
    //   flex-direction: column-reverse;
    // }

    .title-holder {
      order: 1;

      @media only screen and (max-width: 767px) {
        order: 2;
      }

      @media only screen and (max-width: 1180px) {
        order: 1;
      }
      h3{
        @media (max-width:500px){
          font-size: 17px;
        }
      }
    }

    .right-content {
      display: flex;
      align-items: center;
      gap: 16px;
      order: 2;
      // margin-bottom: 30px;

      a {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: #D8D8D8;

        @media only screen and (max-width: 420px) {
          font-size: 12px;
          gap: 5px;
        }

        img {
          transform: rotate(270deg);
        }
      }


      @media only screen and (max-width: 767px) {
        order: 1;
      }

      @media only screen and (max-width: 1180px) {
        order: 2;
      }

      // sorting
      .sort-dropdown {
        border: 1.5px solid #9247FF;
        padding: 16px 25px;
        border-radius: 40px;
        width: -webkit-fill-available;
        max-width: 180px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin: 0;
        }

        .open-dropdown {
          position: absolute;
          bottom: -110px;
          background: #0C031A;
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 16px;
          width: 192px;
          padding: 5px 20px;
          right: 0;

          p {
            margin-block: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              display: flex;
              border: 2px solid rgba(255, 255, 255, 0.24);
              border-radius: 50%;
              width: 18px;
              height: 18px;
              position: relative;

              svg {
                position: absolute;
                width: 18px;
                height: 18px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            &:hover {
              color: #9247FF;
            }
          }
        }
      }
    }

    .title {
      font-weight: 700;
      font-size: clamp(22px, 5vw, 28px);
      line-height: clamp(38px, 5vw, 28px);
      margin: 0;
    }

    .search-holder {
      width: 345px;
      border: 1.5px solid $accentClr;
      border-radius: 100px;
      padding: 15px;
      margin: 0;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        width: 100%;
      }

      input {
        &.text {
          width: calc(100% - 35px);
          background-color: transparent;
          box-shadow: none;
          border: 0px;
          color: #fff;
          margin-right: 15px;
        }

        &.submit {
          background: url(https://assets-cdn.virtua.com/images/Virtua_home/search-icon.svg) no-repeat;
          width: 18px;
          height: 18px;
          font-size: 0px;
          background-size: 100%;
          cursor: pointer;
          border: 0px;
        }
      }
    }
  }

  .cribs-listing {
    padding-top: 56px;
    padding-bottom: 58px;
    z-index: 5;
    position: relative;
    @media (max-width:600px){
      padding-top: 0;
    }

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: url(https://cdn.virtua.com/Virtua_home/gradient-bg.webp) no-repeat 100% 20%;
      background-size: cover;
    }

    .cribs-condos-buttons {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-bottom: 40px;

      .bar {
        height: 36px;
        width: 1px;
        background: rgba(255, 255, 255, 0.15);

        @media (max-width: 840px) {
          display: none;
        }
      }

      .cribs-filter-buttons {
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
          justify-content: center;
        }

        button {
          margin: 0 8px;
          min-width: 114px;
          border: 0;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 40px;
          text-align: center;
          padding: 12px 0px;
          color: rgba(255, 255, 255, 0.5);
          transition: all 0.2s linear;

          @media (max-width: 600px) {
            margin: 0 4px;
            min-width: 100px;
          }

          cursor: pointer;

          &.active {
            color: #FFFFFF;
            background: #9247FF;
          }

          &:focus {
            outline: none;
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .cribs-sizes-buttons {
        position: relative;
        display: flex;
        align-items: center;

        // padding-block: 20px;
        &::before {
          @media (max-width:767px) {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 2px;
            background: rgba(255, 255, 255, 0.06);
          }
        }

        &::after {
          @media (max-width:767px) {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: rgba(255, 255, 255, 0.06);
          }
        }

        button {
          margin: 0 8px;
          border-radius: 40px;
          min-width: 114px;
          padding: 12px 0;
          color: #F4F4F4;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;

          @media (max-width: 600px) {
            margin: 0 4px;
            min-width: 100px;
          }

          &.not-selected {
            cursor: pointer;

            &:hover {
              background: #9247FF;
            }

            background-color: transparent;
            border: 1.5px solid rgba(255, 255, 255, 0.15);
          }

          &.selected {
            background: rgba(146, 71, 255, 0.2);
            border: 1.5px solid #9247FF;
          }

          img {
            margin-right: 5px;
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          padding-block: 20px;
        }
      }

      @media (max-width: 840px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

}

.cribs-listing {
  @media only screen and (max-width: 767px) {
    margin-top: 50px;
  }

  .container {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 40px;

    @media (max-width:1550px) {
      padding: 0 40px;
    }

    @media (max-width:600px) {
      padding: 0 15px;
    }
  }

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .custom-col {
    padding: 0 12px;
    flex: 0 0 20%;
    max-width: 20%;

    @media (max-width:1550px) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    // @media only screen and (min-width: 768px) and (max-width: 1180px) and (orientation : portrait){
    @media only screen and (min-width: 768px) and (max-width: 1180px) and (orientation : portrait) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1180px) and (orientation : landscape) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }

  .crib {
    margin-bottom: 24px;
    border: 1.5px solid rgba(255, 255, 255, 0.20);
    border-radius: 20px;
    padding: 4px 4px 17px;

    .img-holder {
      position: relative;
      border-radius: 8px;
      margin-bottom: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 210px;

      .img {
        width: 100%;
        min-height: 210px;
        object-fit: cover;
        object-position: center;
        border-radius: 16px;

        &.placeholder {
          border-radius: 0px;
          max-height: 100px;
          max-width: 100px;
          min-height: 100px;
        }
      }

      .options {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        opacity: 0;
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        transition: all linear 0.25s;
        z-index: -1;
        border-radius: 8px;
      }

      .btn {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 118px !important;
        max-width: 118px !important;
        height: 36px;
        padding: 0px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        border: none;

        &.link {
          max-width: 34px !important;
          min-width: 34px !important;
          height: 34px;
          left: auto;
          right: 20px;
          top: 10px;
          transform: translate(10px);
          background: #9247FF;

          &:hover {
            background: linear-gradient(86deg, #9247FF 0%, #AE01FF 100%);
          }
        }

        &.flag {
          right: 60px;
          background: #9247FF;

          &:hover {
            background: linear-gradient(86deg, #9247FF 0%, #AE01FF 100%);
          }
        }
      }

      &:hover {
        .options {
          opacity: 1;
          z-index: 1;
        }

        .btn {
          opacity: 1;
        }
      }

      .card-actions {
        position: absolute;
        bottom: 12px;
        display: flex;
        // gap: 10px;
        right: 15px;
        font-size: 12px;
        align-items: center;
        z-index: 100;

        div {
          &:first-child {
            margin-right: 12px;
          }
        }

        .card-views,
        .card-likes {
          display: flex;
          align-items: center;

          // gap: 5px
          span {
            padding-left: 5px;
          }
        }

        .card-likes {
          div {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          img {
            cursor: pointer;
          }
        }
      }
    }

    .bottom {
      padding: 0 4px 0;
      font-size: 12px;

      @media only screen and (max-width: 767px) {
        padding: 0 5px 10px;
      }

      @media only screen and (max-width: 1180px) {
        padding: 0 5px 10px;
      }
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      margin: 0 auto 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 24px;
      margin-bottom: 10px;
    }

    .meta {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .owner {
        color: $accentClr;
        font-size: 14px;
        font-weight: 500;
      }

      .pin {
        @media only screen and (max-width: 325px) {
          font-size: 10px;
        }

        img {
          position: relative;
          top: 7px;
          margin-right: 2.5px;
        }
      }

      .tag {
        border-radius: 100px;
        border: 1.5px solid rgba(255, 255, 255, 0.20);
        padding: 0 7px;
        font-weight: 500;
        line-height: 20px;

        @media only screen and (max-width: 325px) {
          padding: 0 5px;
          font-size: 10px;
        }
      }
    }
  }
}

.loader-container {
  padding: 100px 15px;
  text-align: center;
  width: 100%;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;

  .img_loader {
    background-color: $accentClr;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin: auto;

    img {
      max-width: 55%;
      max-height: 55%;
    }
  }
}

// copied-text
.copied-text {
  position: absolute;
  right: 0px;
  font-size: 12px;
  background: rgba(0, 0, 0, .75);
  padding: 2.5px 7.5px;
  border-radius: 5px;
  top: -5px;
  z-index: 2;
}

.crib-condos {
  // .forehead {
  //   @media (max-width: 767px) {
  //     flex-direction: row;
  //   }
  // }

  .sort-dropdown {
    @media (max-width: 767px) {
      width: 48px !important;
      height: 48px;
    }
  }

  .web-view {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .mobile-view {
    display: none;

    @media (max-width: 767px) {
      display: block;
      height: 24px;
    }
  }
}