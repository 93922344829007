.developer-banner {
  position: relative;
  width: 100%;
  height: auto;
  @media (max-width:600px){
    margin-top: 30px;
  }

  .mainBanner {
    background: url(https://cdn.virtua.com/Virtua_home/developer-banner.webp) no-repeat top center;
    background-size: cover;
    background-color: rgba($color: #000000, $alpha: 1);
    min-height: 100vh;
    height: 100%;

    .container {
      height: 100vh;
      position: relative;
      width: 100%;
      max-width: 1520px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 15px;
    }
  }

  .wrapper {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 4;

    // @media only screen and (min-width: 320px) and (max-width: 767px) {
    //   align-items: end;
    // }

    .txt-holder {
      max-width: 916px;
      padding-top: 40px;
      padding-bottom: 40px;
      @media (max-width:992px){
        padding: 40px 30px 40px;
      }
      @media (max-width:600px){
        padding: 40px 0 40px;
      }
      h2{
        margin: 0 0 16px;
      }
    }
  }

}


@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}