.not-found{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    .not-found-content {
        position: relative;
        z-index: 1;
        h2{
            font-size: 48px;
            font-weight: 700;
            margin-top: 50px;
            line-height: 48px;
            margin-bottom: 0;
            @media (max-width: 600px){
                font-size: 36px;
            }
        }
        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
        }
        a{
            font-size: 16px;
            color: #9247FF;
        }
    }
}