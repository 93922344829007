@import "node_modules/react-modal-video/scss/modal-video.scss";
.game_not_found{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80%;
  @media (max-width:480px){
    padding-top: 40px;
  }
  svg{
    margin: 0 0 20px;
  }
  h3{
    font-weight: 600;
    margin: 0 0 10px !important;
  }
  p{
    margin: 0 0 25px;
  }
  a{
    color: #fff;
    &:hover{
      color: #9247ff;
    }
  }
}
.main_filter_holder {
  gap: 40px;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    gap: 25px;
  }

  @media (max-width: 992px) {
    gap: 20px;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  h3 {
    margin: 0 0 24px;

    @media (max-width: 600px) {
      margin: 0 0 14px;
    }
  }

  .mobile_filter_holder {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    padding: 10px;
    line-height: 0;

    @media (min-width: 800px) {
      display: none;
    }
  }

  .mobile_filter_menu {
    @media (max-width: 800px) {
      position: fixed;
      left: 0;
      border-radius: 16px 16px 0px 0px;
      border-top: 2px solid rgba(146, 71, 255, 0.3);
      background: rgba(6, 0, 28, 0.93);
      backdrop-filter: blur(15px);
      top: 100%;
      height: 100%;
      width: 100%;
      z-index: 1000;
      display: flex;
      padding: 30px 40px 15px;
      flex-direction: column;
      transition: all .7s linear;
    }

    &.active {
      display: flex;
      top: 56%;
      height: 50%;
      overflow-y: auto;

      @media (max-width:480px) {
        top: 20%;
        height: 80%;
      }
    }

    @media (max-width: 480px) {
      padding: 15px 15px 15px;
    }

    .close_filter {
      position: absolute;
      top: 14px;
      right: 14px;

      @media (min-width: 800px) {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
  }

  .mobile_text_filter {
    @media (max-width:800px) {
      display: none;
    }
  }
}

.platform_holder {
  display: flex;
  align-items: center;

  img {
    margin-left: 15px;
    height: 20px;
  }
}

.col-left-side {
  flex: 0 0 auto;
  width: 252px;

  @media (max-width: 1024px) {
    width: 225px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  // @media (max-width: 600px) {
  //   width: 100%;
  // }
}

.custom_checkbox {
  display: block;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    margin-right: 16px;
    margin-bottom: 22px;
    min-width: 30%;
  }

  @media (max-width: 480px) {
    margin-right: 12px;
    margin-bottom: 12px;
    min-width: 100%;
  }
}

.custom_checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom_checkbox label {
  position: relative;
  cursor: pointer;

  // text-transform: capitalize;
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.custom_checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 3px solid #9d9d9dbf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 5px;

  @media (max-width: 600px) {
    padding: 8px;
    border: 2px solid #9d9d9dbf;
  }
}

.custom_checkbox input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 14px;
  height: 14px;
  background: #fff;
  border-width: 0 2px 2px 0;

  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
    left: 4px;
    top: 4px;
  }
}

.custom_checkbox input:checked+label::before {
  background-color: #9247ff;
  border: 3px solid #9247ff;

  @media (max-width: 600px) {
    border: 2px solid #9247ff;
  }
}

.filter_tab {
  border-radius: 16px;
  border: 1px solid rgba(146, 71, 255, 0.3);
  background: linear-gradient(180deg,
      rgba(223, 201, 255, 0.08) 0%,
      rgba(146, 71, 255, 0.08) 100%);
  margin-bottom: 16px;
  padding: 20px;

  @media (max-width: 800px) {
    // padding: 12px 12px 0 12px;
    padding: 0;
    border: none;
    background: none;
  }

  @media (max-width: 480px) {
    padding: 12px 12px 0 12px;
  }

  &.column-direction {
    @media (max-width: 800px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 30px;

    @media (max-width: 800px) {
      flex-basis: 100%;
      margin: 0 0 20px;
    }

    @media (max-width: 480px) {
      margin: 0 0 12px;
    }
  }
}

.col-right-side {
  flex: 1 1;
  width: 0;
}

.main_holder_card {
  margin: 0 -16px;

  @media (max-width: 1550px) {
    margin: 0 -10px;
  }
}

.col-item-card {
  flex: 0 0 auto;
  width: 20%;
  padding: 0 16px;

  @media (max-width: 1550px) {
    width: 25%;
    padding: 0 10px;
  }

  @media (max-width: 1250px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-fill-color: #fff;
    letter-spacing: 0.75px;
  }

  button,
  a {
    width: 100%;
    min-width: auto;
    padding: 6px 25px;
  }

  .game_tag {
    margin: 12px 0 0;
    display: block;
    border-radius: 100px;
    background: #fff;
    color: #000;
    padding: 3px 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    width: max-content;
  }
}

.nft__item {
  margin-bottom: 40px;
  padding: 14px 14px 24px;
  border-radius: 16px;
  border: 2px solid rgba(146, 71, 255, 0.16);
  background: linear-gradient(340deg, #130144 0%, #13092e 100%);
  cursor: pointer;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
}

.nft__item_wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 220px;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 12px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.nft__item_preview {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.games-landing-page {
  .txt-holder {
    padding-top: 40px;
    padding-bottom: 40px;

    h2 {
      margin: 0 0 16px;

      @media (max-width: 992px) {
        max-width: 680px;
        line-height: 44px;
      }

      @media (max-width: 600px) {
        line-height: 34px;
      }
    }

    @media (max-width: 992px) {
      p {
        text-align: left;
      }
    }
  }

  .mainBanner {
    background: url(https://cdn.virtua.com/Virtua_home/game-experience-bg.webp) no-repeat top center;
    background-size: cover;
    background-color: rgba($color: #000000, $alpha: 1);
    min-height: 540px;
    margin: 0 0 80px;

    @media (max-width: 830px) {
      background: url(https://cdn.virtua.com/Virtua_home/game-experience-bg-mb.png) no-repeat top center;
      background-size: cover;
    }

    @media (max-width: 600px) {
      margin: 0 0 35px;
      min-height: 500px;
    }

    .container {
      height: 540px;

      @media (max-width: 600px) {
        height: 500px;
      }
    }
  }

  .wrapper {
    position: relative;
    grid-template-columns: 1fr;
    align-items: end;
    // justify-items: center;
    width: 100%;
    height: 100%;
    // text-align: center;
    z-index: 4;

    .txt-holder {
      max-width: 916px;

      .section-title {
        margin-top: 0;
        text-transform: uppercase;

        &.web-title {
          @media (max-width: 600px) {
            display: none;
          }
        }

        &.mbl-title {
          display: none;

          @media (max-width: 600px) {
            display: block;
          }
        }
      }

      .cta {
        margin-top: 1rem;

        .custom-btn {
          text-transform: uppercase;
        }
      }
    }

    .scrollDown {
      align-self: flex-end;
      position: absolute;
      bottom: 90px;

      .icon svg {
        width: 16px;
        height: 25px;
      }

      p {
        font-size: 12px;
        margin-top: 1rem;
      }
    }
  }

  .vflect-battle {
    background: url(https://assets-cdn.virtua.com/images/Virtua_home/game-bg-two.jpg) no-repeat top center;
    background-size: cover;

    .wrapper {
      align-items: flex-start;
      padding-bottom: 160px;
    }

    .txt-holder {
      max-width: 1100px;
      padding-top: 100px;
    }

    .video-section {
      position: relative;
      max-width: 800px;
      width: 100%;
      height: 450px;
      border-radius: 24px;
      overflow: hidden;

      .modal-video {
        background-color: rgba(0, 0, 0, 0.95);
      }

      .modal-video-close-btn {
        cursor: pointer;
        top: -18px;
        right: -18px;
        border-radius: 100%;
        background-color: var(--accentClr);

        &::before {
          height: 3px;
          top: 60%;
          left: 8px;
          width: 50%;
        }

        &::after {
          height: 3px;
          top: 60%;
          left: 8px;
          width: 50%;
        }
      }

      .videoThumb {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .yt-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 72px;
        height: 52px;
        background: url(https://assets-cdn.virtua.com/images/Virtua_home/YT.png) no-repeat top center;
        background-size: cover;
        border: none;
        cursor: pointer;
      }

      .modal-video-body {
        max-width: 95%;
      }
    }
  }

  .vflext-runner {
    padding: 540px 0;
    position: relative;

    .container {
      height: 100%;
    }

    .wrapper {
      align-items: flex-end;
      justify-items: center;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(https://assets-cdn.virtua.com/images/Virtua_home/videobase.png) no-repeat;
        background-position: center;
        background-size: cover;
        z-index: 2;
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    .txt-holder {
      position: relative;
      bottom: 100px;
      z-index: 3;
      max-width: 870px;

      .title-img {
        margin-bottom: -30px;
      }
    }
  }

  .games-section {
    position: relative;

    .container {
      position: relative;
      max-width: 1500px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 0 15px;
    }

    .cards {
      position: relative;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      margin: 150px 0px;
      text-align: center;
    }

    .wrapper {
      display: grid;
      grid-auto-rows: minmax(200px, auto);
      grid-template-columns: repeat(6, 1fr);
      align-items: center;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .section-title {
      margin-bottom: 25px;
    }

    .section-detail {
      margin: 0 auto;
      max-width: 1100px;
    }

    .img-holder {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-radius: 44px;

      img {
        width: 100%;
      }
    }

    .custom-btn {
      min-width: 240px;
      cursor: pointer;
    }
  }

  .metaverse-league {
    margin: 0px auto;
    padding: 200px 0px;
    position: relative;

    @media only screen and (min-width: 320px) and (max-width: 1024px) {
      padding: 100px 0px;
    }

    .inner {
      display: flex;
      align-items: center;
      margin: 0 -15px;

      @media only screen and (min-width: 320px) and (max-width: 900px) {
        flex-direction: column;
      }
    }

    .content {
      width: 45%;
      flex: 0 0 auto;
      padding: 0 15px;

      @media only screen and (min-width: 320px) and (max-width: 900px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
      }

      @media only screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {}

      .section-title {
        text-transform: uppercase;
      }

      .section-detail {
        margin-bottom: 0px;
        font-size: 16px;
      }

      .league-list {
        display: flex;

        .list-col {
          flex: 1 1;
        }

        .list-none {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            padding-bottom: 16px;
          }
        }

        @media only screen and (min-width: 320px) and (max-width: 900px) {
          flex-direction: column;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          flex-direction: row;
        }

        @media only screen and (min-width: 320px) and (max-width: 990px) and (orientation: landscape) {
          flex-direction: row;
        }
      }
    }

    .brands {
      display: flex;
      flex-wrap: wrap;
      align-self: flex-end;
      width: 55%;
      flex: 0 0 auto;
      padding: 0 15px;

      @media only screen and (min-width: 320px) and (max-width: 900px) {
        width: 100%;
        align-self: center;
        padding: 0 5px;
      }

      // @media only screen and (min-width: 768px) and (max-width: 1180px) {
      //   width: 55%;
      // }

      .img-holder {
        width: 33.3%;
        flex-direction: column;
        flex: 0 0 auto;
        padding: 0 12px;
        margin-bottom: 24px;

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 16px;
          height: 140px;
        }

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          width: 50%;
          flex: 0 0 50%;
          padding: 0 7.5px;
          margin-bottom: 15px;
        }

        @media only screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
          width: 33.3%;
          flex: 0 0 33.33%;
        }

        // @media only screen and (min-width: 768px) and (max-width: 1180px) {
        //   width: 47.2%;
        // }

        img {
          max-width: 70%;
          max-height: 70%;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .games-landing-page {
    .wrapper .scrollDown {
      bottom: 20px;

      p {
        margin-top: 10px;
      }
    }

    .vflect-battle {
      min-height: fit-content !important;

      .container {
        height: 100% !important;
      }

      .video-section {
        height: 220px !important;
      }
    }

    .games-section {
      .wrapper {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .games-landing-page .vflect-battle.h-screen {
    min-height: 100%;
    height: 100%;
  }

  .games-landing-page .vflect-battle {
    min-height: fit-content !important;
    height: 100% !important;

    .video-section {
      height: 400px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .games-landing-page {
    .vflect-battle.h-screen {
      min-height: fit-content;

      .container {
        height: 100% !important;
      }
    }
  }

  .games-section {
    .wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .games-landing-page .metaverse-league .inner {
    gap: 10px;
  }

  .games-landing-page .metaverse-league .brands .img-holder {
    width: 220px;
  }
}