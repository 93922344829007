.join-virtua-form {
    padding: 166px 15px 0;
    @media (max-width:1024px){
        padding: 100px 15px 0;
    }
    @media (max-width:600px){
        padding: 70px 15px 0;
    }

    .error_msg {
        min-height: 24px;

        small {
            display: block;
            text-align: left;

            &.has-success {
                color: #21B470;
            }

            &.has-error {
                color: red;
            }
        }

        // .has-success {
        //     color: #21B470;
        // }
        // .has-error {
        //     color: red;
        // }
    }
    .mb-0{
        margin-bottom: 0 !important;
    }
}

.login-form {
    padding: 105px 115px;
    width: 100%;
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    border-radius: 16px;
    border: 1px solid rgba(146, 71, 255, 0.30);
    background: linear-gradient(180deg, rgba(223, 201, 255, 0.08) 0%, rgba(146, 71, 255, 0.08) 100%);
    // backdrop-filter: blur(3px);
    text-align: center;
    margin-bottom: 120px;
    position: relative;

    @media only screen and (max-width: 1080px) {
        padding: 80px 30px;
        gap: 15px;
    }
    @media only screen and (max-width: 1024px) {
        max-width: 1024px;
    }

    @media only screen and (max-width: 600px) {
        padding: 50px 15px;
        gap: 13px;
    }

    .gap-23 {
        gap: 23px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;

        textarea {
            height: 138px;
            max-height: auto;
            min-height: 138px;
            overflow: auto;
            resize: none;
        }
    }

    label {
        margin-bottom: 10px;
        color: rgba(255, 255, 255, 0.70);
        font-size: 16px;
        letter-spacing: -0.48px;
    }

    .form-control {
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.06);
        background: rgba(255, 255, 255, 0.04);
        // backdrop-filter: blur(10px);
        padding: 22px 16px;
        height: 54px;
        margin-bottom: 24px;
        color: #fff;
        font-size: 14px;
        letter-spacing: -0.42px;
        width: 100%;

        &.red {
            border: 1px solid red;
        }
    }
    .btn-submit{
        max-width: 670px;
        margin: 0 auto;
    }
    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: url(https://cdn.virtua.com/Virtua_home/gradient-bg.webp) no-repeat 100% 100%;
        background-size: cover;
    }
}