@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap');

@font-face {
  font-family: 'Clash Display';
  src: url('../fonts/ClashDisplay-Regular.woff2') format('woff2'),
    url('../fonts/ClashDisplay-Regular.woff') format('woff'),
    url('../fonts/ClashDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Clash Display';
  src: url('../fonts/ClashDisplay-Medium.woff2') format('woff2'),
    url('../fonts/ClashDisplay-Medium.woff') format('woff'),
    url('../fonts/ClashDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Clash Display';
  src: url('../fonts/ClashDisplay-Semibold.woff2') format('woff2'),
    url('../fonts/ClashDisplay-Semibold.woff') format('woff'),
    url('../fonts/ClashDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Clash Display';
  src: url('../fonts/ClashDisplay-Bold.woff2') format('woff2'),
    url('../fonts/ClashDisplay-Bold.woff') format('woff'),
    url('../fonts/ClashDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: auto;
  /* scrollbar-color: #9247ff #111; */
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #434343;
  border-radius: 8px;
  box-shadow: inset 2px 2px 2px #9247ff, inset -2px -2px 2px #9247ff;
}

::-webkit-scrollbar-track {
  background: linear-gradient(90deg, 111, 111 1px, #111 0, #111);
}

body {
  margin: 0;
  color: var(--whiteClr);
  min-width: 320px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  font: 400 18px/24px "Clash Display" !important;
  letter-spacing: 0.3px;
  background: var(--bodyBg);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

#main {
  width: 100%;

}

.relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

.fixed {
  position: fixed;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: block;
}

.list-none {
  margin: 0;
  padding: 0;
  list-style: none;
}

.h-100 {
  height: 100%;
}

.container.fluid {
  width: 100%;
  max-width: none;
}

.container:after {
  display: block;
  clear: both;
  content: '';
}

.word-break {
  word-break: break-word;
}

.d-block {
  display: block;
}

img {
  vertical-align: top;
  border-style: none;
}

.img-fluid,
img {
  max-height: 100%;
  max-width: 100%;
}

a {
  color: #007bff;
  text-decoration: none;
  display: inline-block;
  background-color: transparent;
}

::placeholder {
  color: #A3A3A3;
}

:-ms-input-placeholder {
  color: #A3A3A3;
}

::-ms-input-placeholder {
  color: #A3A3A3;
}

textarea.form-control {
  height: auto;
}

textarea {
  overflow: auto;
  resize: vertical;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

button:focus,
textarea:focus,
input:focus,
select {
  outline: none;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

button,
input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.d-none {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.flex-text-right {
  justify-content: flex-end;
}

.flex-text-center {
  justify-content: center;
}

.column-direction {
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.m-0 {
  margin: 0;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.p-0 {
  padding: 0;
}

.pr-0 {
  padding-right: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pointer {
  cursor: pointer;
}

.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.opacity-7 {
  opacity: 0.7;
}

.under-line {
  text-decoration: underline;
}

.fw-600 {
  font-weight: 600;
}

.white {
  color: var(--whiteClr);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #A3A3A3;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: 5000s ease-in-out 0s;
}


/* 14/11/2022 - TVK & Games */
:root {
  --bodyBg: #06001C;
  --accentClr: #9247ff;
  --whiteClr: #ffffff;
  --blackClr: #242424;
  --mutedClr: #9a9a9a;
  --greenClr: #39c64f;
  --redClr: #c63939;
  --tvkClr: #03fff2;
  --binanceClr: #f0b90b;
  --uniSwapClr: #FF007A;
  --coinbaseClr: #0052FF;
  --krakenClr: #5E3FDE;
  --ascendExClr: #3BD5EE;
  --bybitClr: #F7A600;
  --gateClr: #0068FF;
  --kucoinClr: #23AF91;
  --mexcClr: #1877F2;
  --paribuClr: #8fa63c;

  --primaryFont: "Poppins", sans-serif;
  --secondaryFont: "Quantico", sans-serif;
}

.tvk-games {
  position: relative;
  width: 100%;
  height: auto;
}

.space-10 {
  margin-bottom: 12px;
}

.purple{
  color: var(--accentClr) !important;
}

.tvk-games .container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
}

.game-filter #header{
  pointer-events: none;
}

@media (max-width: 600px) {
  .tvk-games .container {
    padding: 0 15px;
  }
}

.tvk-games .tagline {
  font-size: clamp(20px, 5vw, 26px);
  font-weight: 500;
  line-height: clamp(20px, 5vw, 39px);
  color: var(--accentClr);
  text-transform: uppercase;
}

.tvk-games .section-title {
  font-size: clamp(34px, 5vw, 56px);
  font-weight: 700;
  line-height: clamp(38px, 5vw, 56px);
  color: var(--whiteClr);
  margin-bottom: 20px;
  letter-spacing: -0.03em;
}

.tvk-games .section-detail {
  font-size: clamp(14px, 2vw, 18px);
  line-height: clamp(24px, 5vw, 28px);
  color: var(--whiteClr);
  letter-spacing: -0.03em;
}

.tvk-games .custom-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border: 2px solid transparent;
  font-size: 16px;
  color: var(--whiteClr);
  font-weight: 500;
  padding: 0.375rem 0.75rem;
  border-radius: 100px;
  text-decoration: none;
  transition: all .25s ease-in-out;
}

.tvk-games .custom-btn:hover {
  opacity: .75;
}

.tvk-games .custom-btn.dark {
  background-color: var(--accentClr);
}

.tvk-games .custom-btn.dark-outline {
  background-color: transparent;
  border: 2px solid var(--accentClr) !important;
}

.tvk-games .custom-btn.light {
  background-color: var(--whiteClr);
  color: var(--blackClr);
}

.tvk-games .custom-btn.lg {
  height: 54px;
  line-height: 54px;
}

.tvk-games .custom-btn.md {
  height: 48px;
  line-height: 48px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

  .tvk-games .custom-btn.lg,
  .tvk-games .custom-btn.md {
    height: 50px;
    line-height: 44px;
  }
}

.tvk-games .custom-btn.sm {
  height: 40px;
  line-height: 40px;
}

.tvk-games .custom-btn.icon-btn .icon {
  display: inline-block;
  margin-right: 10px;
}

.tvk-games .custom-btn.icon .icon svg {
  width: 20px;
  height: 14px;
}

.tvk-games .wrapper {
  display: grid;
  gap: 15px;
}

.tvk-games .custom-btn {
  min-width: 200px;
}

.tvk-games .h-screen {
  min-height: 100vh;
  height: 100%;
}

.Toastify .Toastify__progress-bar--success{
  background: #9247ff !important;
}

@media(max-width:500px){
  .Toastify .Toastify__toast-container {
    width: 95vw !important;
    top: 5px !important;
    left: 9px !important;
}
}