@import "node_modules/react-modal-video/scss/modal-video.scss";

.link {
  color: #9247ff;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.vote-tvk {
  padding: 120px 15px 160px;
  width: 100%;
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 60px;
  }

  .mainBanner {
    border-radius: 36px;
    margin-bottom: 50px;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      background: url(https://assets-cdn.virtua.com/images/Virtua_home/voting-banner-mob-bg.png) no-repeat top center;
      background-size: cover;
      background-color: rgba($color: #000000, $alpha: 1);
      border-radius: 16px;
    }

    .container {
      height: 740px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        height: 640px;
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      border-radius: 36px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        display: none;
      }

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        object-position: left;
      }
    }

    .txt-holder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(0%, -50%);
      z-index: 100;
      max-width: 710px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        width: 100%;
        padding-inline: 15px;
        left: 0;
        top: auto;
        bottom: 30px;
        transform: inherit;
        text-align: center;
      }

      @media only screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
        transform: translate(-3%, -50%);
      }

      .link {
        color: #fff;

        &:hover {
          color: #9247ff;
        }
      }
    }

    .section-title {
      font-size: 100px;
      line-height: 100%;
      /* 72px */
      letter-spacing: -3px;
      text-transform: uppercase;
      margin-bottom: 36px;
      margin-top: 0;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 50px !important;
        margin-block: 10px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 60px !important;
      }
    }

    .section-detail {
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.64px;
      margin-bottom: 0;
      text-transform: uppercase;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 16px;
        line-height: 28px;
        margin-block: 10px;
      }
    }

    .btns {
      display: flex;
      gap: 10px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        flex-direction: column;
      }
    }

    .custom-btn {
      padding: 20px 40px;
      border-radius: 100px;
      background: #FFF;
      color: #101010;
      font-weight: 600;
      transition: all 0.3s ease;
      min-width: 180px;
      height: 56px;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #9247ff;
        color: #FFF;
      }

      &.dark {
        background: linear-gradient(114deg, #03d9af 0%, #50fddb 100%);
        color: var(--blackClr);

        &:hover {
          opacity: .75;
        }
      }

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        padding-top: 14px;
        padding-bottom: 14px;
        height: 44px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 14px;
        padding: 10px 15px;
        min-width: auto;
        height: 48px;
      }

      @media only screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
        padding: 20px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 767px) {}
  }

  .voting-content {
    padding: 60px 120px;
    border-radius: 36px;
    background: #FFF;
    color: #000000;
    display: none;
    // margin-bottom: 80px;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      padding: 40px 15px;
      border-radius: 16px;
      // margin-bottom: 40px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 40px 30px;
    }

    .text {
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        margin-bottom: 30px;
      }

      .title {
        font-size: 48px;
        line-height: 100%;
        letter-spacing: -1.92px;
        text-transform: uppercase;
        margin-bottom: 24px;
        margin-top: 0;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 32px;
          margin-bottom: 18px;
        }
      }

      .heading {
        font-size: 32px;
        line-height: 100%;
        letter-spacing: -0.96px;
        text-transform: capitalize;
        margin-bottom: 24px;
        margin-top: 0;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 12px;
        }
      }

      p {
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.54px;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 16px;
          line-height: 26px;
        }
      }

      .heading-2 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.72px;
        font-weight: 600;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      li {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.54px;
        margin-bottom: 15px;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }


    .profile {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 16px;
      padding-top: 16px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        gap: 12px;
      }

      .profile-img {
        border-radius: 50%;
        height: 64px;
        width: 64px;
        background: #D9D9D9;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          height: 40px;
          width: 40px;
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .profile-text {
        display: flex;
        flex-direction: column;

        p {
          font-size: 18px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: -0.54px;
          margin: 0;

          @media only screen and (min-width: 320px) and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .faqs {
    display: none;
    padding: 80px 120px 0;
    position: relative;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      padding: 40px 0 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1180px) {
      padding: 40px 0 0;
    }

    &::before {
      content: '';
      position: absolute;
      background: url(https://assets-cdn.virtua.com/images/Virtua_home/vanry-gradient.png) no-repeat top;
      background-size: 100% 80%;
      top: -50%;
      left: 0;
      width: 100%;
      bottom: 0;
    }

    .section-title {
      font-size: 60px;
      line-height: 100%;
      letter-spacing: -2.4px;
      text-transform: uppercase;
      margin-bottom: 24px;
      margin-top: 0;
    }

    .accordion__item {
      margin-bottom: 16px;
    }

    .accordion__button {
      padding: 32px 40px;
      display: flex;
      align-items: center;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.10);
      backdrop-filter: blur(20px);
      color: #FFF;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.6px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        padding-inline: 20px 40px;
        padding-block: 16px;
        border-radius: 16px;
      }
    }

    .accordion__button[aria-expanded='true'],
    .accordion__button[aria-selected='true'] {
      border-radius: 24px 24px 0 0;
      padding-bottom: 16px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        border-radius: 16px 16px 0 0;
      }
    }

    .accordion__button:before {
      position: absolute;
      right: 40px;
      transform: rotate(45deg);

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        right: 20px;
      }

      @media (max-width: 400px) {
        right: 10px;
      }

    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
      transform: rotate(225deg);
    }


    .accordion__panel {
      border-radius: 0 0 24px 24px;
      background: rgba(255, 255, 255, 0.10);
      backdrop-filter: blur(20px);
      padding-inline: 40px;
      padding-block: 0 32px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        padding-inline: 20px;
        padding-bottom: 16px;
        border-radius: 0 0 16px 16px;
      }

      p {
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.54px;
        margin: 0;
        color: #DBDBDB;
        font-weight: 400;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      li {
        margin-bottom: 10px;

        p {
          margin-top: 5px;
        }
      }
    }

    .faqs-img {
      text-align: center;

      img {

        margin: 50px 50px;
        display: inline-block;
        width: 100%;
        max-width: 350px;
        border-radius: 16px;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
          width: 100%;
          margin: 30px 5px;
        }
      }
    }

    .voting-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      position: relative;
      z-index: 9999;
      margin-top: 80px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        margin-top: 40px;
      }
    }
  }

}

@media only screen and (min-width: 320px) and (max-width: 767px) {}

@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}