.land-sale-page{
  background: #080210;
  z-index: 0;
  padding: 150px 15px 0;
  @media(max-width: 600px) {
    padding: 70px 15px 0;
  }
  &:before{
    background: url("https://assets-cdn.virtua.com/images/Virtua_home/bg-gradient-left.png") no-repeat left top;
    background-size: contain;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
  }
  &:after{
    background: url("https://assets-cdn.virtua.com/images/Virtua_home/bg-gradient-right.png") no-repeat right bottom;
    background-size: contain;
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: -1;
  }
}