.inner-content {
  position: relative;
  padding: 36px 38px;
  z-index: 10;
  color: #fff;

  form {
    button:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  @media (max-width: 1550px) {
    padding: 26px 38px;
  }

  @media (max-width: 767px) {
    padding: 26px 20px;
  }

  @media (max-width: 420px) {
    padding: 26px 18px;
  }

  p {
    &.title {
      margin: 0 0 28px !important;
      color: rgba(255, 255, 255, 0.7);
      text-align: left;

      a {
        color: #9247FF;
      }
    }

    &.bottom-title {
      margin: 0 0 18px !important;
      max-width: 440px;
      line-height: 24px;
      text-align: left;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 20px;
    text-align: left;
  }

  ul {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    min-height: 214px;

    @media (max-width: 767px) {
      margin: 0 0 25px;
    }
  }

  ul li {
    color: #FFFFFF;
    position: relative;
    display: flex;
    width: 100%;
    margin: 0 0 14px;
  }

  ul li input[type=radio] {
    position: absolute;
    visibility: hidden;
  }

  ul li label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding: 0 0 0 32px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;

    @media(max-width: 767px) {
      font-size: 14px;
    }

    @media(max-width: 360px) {
      font-size: 13px;
    }
  }

  ul li:hover label {
    color: #FFFFFF;
  }

  ul li .check {
    display: block;
    position: absolute;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 1px;
    left: 0;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
  }

  ul li:hover .check {
    border: 2px solid rgba(255, 255, 255, 0.2);
  }

  ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }

  input[type=radio]:checked~.check {
    border: 2px solid #9247FF;
  }

  input[type=radio]:checked~.check::before {
    background: #9247FF;
  }

  .textarea-holder {
    border: 1.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    background: transparent;
    color: #fff;
    width: 100%;
    height: 99px;
    font-size: 14px;
    padding: 12px;
    margin: 0 0 35px;

    &.as-error {
      border: 1.5px solid red;
    }

    @media (max-width: 767px) {
      margin: 0 0 18px;
    }
  }
}

.textarea-holder::placeholder {
  color: #616161;
  ;
}

.textarea-holder:-ms-input-placeholder {
  color: #616161;
}

.textarea-holder::-ms-input-placeholder {
  color: #616161;
}

.recaptcha {
  margin-bottom: 26px;
}

.btn-height {
  height: 54px;
}

.loader {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  height: 54px;

  img {
    max-width: 40px;
    max-height: 40px;
  }
}

// gamemodal css
.game-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 35px;

  .inner-content {
    padding: 0px;

    .modal-title {
      margin: 0 auto 25px !important;
      max-width: 400px;

      h3 {
        text-align: center;
      }

    }
    .cta{
      margin-top: 20px;
    }
    .custom-btn {
      transition: all 0.3s ease 0s;
      background: rgb(146, 71, 255);
      text-transform: uppercase;
      font-weight: 500;
      color: rgb(255, 255, 255);
      padding: 15px 20px;
      border: 2px solid rgb(146, 71, 255);
      border-radius: 100px;
      cursor: pointer;
      font-size: 16px;
      min-width: 200px;
      text-align: center;
    }
  }
}