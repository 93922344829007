.slider-main-title{
  max-width: 780px;
  margin: 0 auto;
  text-align: center;
  h4{
    margin: 0 0 20px;
  }
  p{
    margin: 0 0 140px;
    @media(max-width: 600px) {
      margin: 0 0 80px;
    }
  }
}
.listing{
  max-width: 1200px;
  margin: 0 auto 115px !important;
  justify-content: space-between;
  padding: 0 25px !important;
  @media(max-width: 1200px) {
    max-width: 992px;
  }
  @media(max-width: 600px) {
    padding: 0 16px;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 80px;
  }
  li{
    color: #9B9B9B;
    font-size: 26px;
    @media(max-width: 1200px) {
      font-size: 24px;
    }
    @media(max-width: 600px) {
      width: 100%;
      font-size: 20px;
    }
    &.active{
      color: #fff;
    }
  }
}
.spaceSwiper{
  .swiper-slide {
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    img{
      border-radius: 20px;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}