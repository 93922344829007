--root {
    --accentClr: #9247FF;
}

#footer {
    padding: 48px 0 32px;
    position: relative;

    @media(max-width: 992px) {
        padding-bottom: 16px !important;
    }

    .footer-top {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 50px;
        padding-bottom: 48px;

        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .web-footer-text {
            // width: 70%;
            flex: 1;

            // @media (max-width: 990px) {
            //     width: 75%;
            // }

            @media (max-width: 900px) {
                width: 100%;
                flex-direction: column;
                gap: 14px;
            }

            img {
                max-width: 48px;
            }

            span {
                font-size: 16px;
                color: #fff;
                margin-left: 24px;
                // opacity: 0.7;
                max-width: 890px;

                @media (max-width: 900px) {
                    margin-left: 0;
                    text-align: center;
                }
            }
        }

        .footer-buttons {
            display: flex;
            justify-content: flex-end;
            width: 400px;

            a {
                &:first-child {
                    margin-right: 15px;

                    @media(max-width: 900px) {
                        margin-right: 10px;
                    }
                }
            }

            @media(max-width: 990px) {
                width: 170px;
                flex-direction: column;
                gap: 8px;
            }

            @media(max-width: 900px) {
                width: 100%;
                justify-content: center;
                flex-direction: row;
            }

            button {
                img {
                    margin-right: 15px;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.16);
            mix-blend-mode: color-dodge;
            backdrop-filter: blur(20px);
        }
    }

    .footer-tabs {
        position: relative;
        width: 100%;
        padding-top: 72px;
        padding-bottom: 72px;

        @media (max-width: 900px) {
            padding-top: 40px;
            padding-bottom: 30px;
        }

        .footer-tabs-inner {
            display: flex;
            gap: 40px;

            @media (max-width: 900px) {
                gap: 10px;
                flex-wrap: wrap;
            }

            .footer-col {
                flex: 1;

                @media (max-width: 900px) {
                    width: 100%;
                    flex: inherit;
                }
            }

            ul {
                li {
                    margin: 0 0 18px;
                    flex: 0 0 45%;
                    @media (max-width:900px){
                        flex: inherit;
                    }

                    a {
                        color: #fff;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: -0.54px;
                        transition: all .2s linear;

                        &:hover {
                            color: #9247ff;

                            img {
                                opacity: .75;
                            }
                        }

                        @media (max-width: 900px) {
                            font-size: 14px;
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }

                    @media (max-width: 900px) {
                        margin-bottom: 10px;
                        display: inline-block;

                        &::after {
                            content: "/";
                            display: inline-block;
                            margin: 0 10px;
                            position: relative;
                            top: 2px;
                        }

                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }

            .footer-social-icons {
                li {
                    margin-right: 15px;

                    &::after {
                        display: none;
                    }
                }

                span {
                    @media (max-width: 900px) {
                        display: none;
                    }
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.16);
            mix-blend-mode: color-dodge;
            backdrop-filter: blur(20px);
        }
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(https://cdn.virtua.com/Virtua_home/gradient-footer-bg.webp) no-repeat 100% 100%;
        background-size: cover;
    }
}

.footer-bottom-list {
    padding-top: 32px !important;

    @media(max-width: 992px) {
        padding-top: 16px !important;
    }

    .bottom-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 30px;

        @media(max-width: 992px) {
            flex-direction: column;
        }

        @media(max-width: 900px) {
            gap: 20px;
        }
    }

    .user-guidelines {
        li {
            margin-left: 25px;
            margin-right: 25px;

            @media (max-width: 900px) {
                margin-left: 8px;
                margin-right: 8px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0.18px;
                transition: all .2s linear;

                &:hover {
                    color: #9247ff;
                }

                @media (max-width: 900px) {
                    font-size: 14px;
                }

                @media (max-width: 400px) {
                    font-size: 12px;
                }
            }
        }
    }

    .powered-text {
        max-width: 195px;
        font-size: 14px;
        gap: 5px;
    }
}

.btn {
    transition: all 0.3s ease;
    background: rgba(217, 217, 217, 0.00);
    text-transform: none;
    font-weight: 600;
    color: #fff;
    padding: 12px;
    max-width: 180px;
    height: 48px;
    width: 100%;
    border: 1.5px solid #fff;
    border-radius: 100px;
    cursor: pointer;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .icon {
        margin-right: 15px;

        @media(max-width: 900px) {
            margin-right: 6px;
            display: inherit;
        }

        img {
            @media(max-width: 9px) {
                width: 15px;
            }
        }
    }

    @media(max-width: 1400px) {
        max-width: 170px;
    }

    @media(max-width: 600px) {
        font-size: 14px;
    }

    @media(max-width: 420px) {
        max-width: 150px;
        padding: 12px;
    }

    @media(max-width: 360px) {
        max-width: 135px;
        font-size: 13px;
        padding: 12px;
    }
}